<template>
  <div class="warp">
    <el-form
      :model="bigForm"
      :rules="rules"
      ref="bigForm"
      v-loading="loading"
      label-width="120px"
    >
      <topOperatingButton
        :isSubmitAddBtn="false"
        :isAuditBillBtn="false"
        @submitForm="submitForm"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
        <template slot="specialDiyBtn">
          <el-button @click="cancel" size="mini">重置</el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom :hideCard="true" style="margin-bottom: 0px">
        <template slot="cardContent">
          <!-- 基本信息模块 -->
          <div class="navTitle">基本信息</div>
          <!-- 基本信息第一行 -->
          <el-row :span="24">
            <el-col :span="8">
              <el-form-item label="客户编码" prop="partnerNo">
                <el-input
                  size="mini"
                  v-model="bigForm.partnerNo"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户名称" prop="partnerName">
                <el-input
                  size="mini"
                  v-model="bigForm.partnerName"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="客户简称" prop="partnerSimpleName">
                <el-input
                  size="mini"
                  v-model="bigForm.partnerSimpleName"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 基本信息第二行 -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="业务员" prop="region">
                <el-select
                  size="mini"
                  placeholder="请选择业务员"
                  v-model="bigForm.saleEmployeeId"
                >
                  <el-option
                    v-for="(item, index) in dataList"
                    :label="item.employeeName"
                    :value="item.employeeId"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户区域" prop="areaId">
                <treeselect
                  size="mini"
                  v-model="bigForm.areaId"
                  :options="treeArea"
                  :show-count="true"
                  placeholder="请选择客户区域"
                  :normalizer="normalizer"
                  @input="inputSelectArea"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户组别" prop="groupId">
                <treeselect
                  size="mini"
                  v-model="bigForm.groupId"
                  :options="treeGroup"
                  :show-count="true"
                  placeholder="请选择客户组别"
                  :normalizer="normalizer"
                  @input="inputSelectGroup"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 基本信息第三行 -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="价格方案">
                <el-select
                  size="mini"
                  placeholder="请选择价格方案"
                  v-model="bigForm.priceCaseId"
                >
                  <el-option
                    v-for="item in priceCaseList"
                    :label="item.priceCaseName"
                    :value="item.priceCaseId"
                    :key="item.priceCaseId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="结算期限">
                <el-select
                  size="mini"
                  placeholder="请选择结算期限"
                  v-model="bigForm.settlementTermId"
                >
                  <el-option
                    v-for="item in saleEmployeeList"
                    :label="item.settlementTermName"
                    :value="item.settlementTermId"
                    :key="item.settlementTermId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>
          <!-- 基本信息第四行 -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="结算方式">
                <el-radio-group v-model="bigForm.payType">
                  <el-radio
                    v-for="dict in dict.type.pay_type"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户类型">
                <el-radio-group v-model="bigForm.partnerType">
                  <el-radio :label="0">客供</el-radio>
                  <el-radio :label="1">客户</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="状态">
                <el-radio-group v-model="bigForm.status">
                  <el-radio
                    v-for="dict in dict.type.common_status"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 账款信息模块 -->
          <div class="navTitle">账款信息</div>
          <!-- 账款信息第一行 -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="授信额度(元)" prop="creditAmount">
                <el-input
                  size="mini"
                  :disabled="disabled"
                  v-model="bigForm.creditAmount"
                  @input="
                    limitInputlength(
                      bigForm.creditAmount,
                      'bigForm.creditAmount'
                    )
                  "
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="已用额度(元)" prop="usedAmount">
                <el-input
                  size="mini"
                  :disabled="disabled"
                  v-model="bigForm.usedAmount"
                  @input="
                    limitInputlength(bigForm.usedAmount, 'bigForm.usedAmount')
                  "
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="预存余额(元)" prop="name">
                <el-input
                  size="mini"
                  :disabled="disabled"
                  v-model="bigForm.prestoreAmount"
                  @input="
                    limitInputlength(
                      bigForm.prestoreAmount,
                      'bigForm.prestoreAmount'
                    )
                  "
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 账款信息第二行 -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="应收欠款(元)" prop="name">
                <el-input
                  size="mini"
                  :disabled="disabled"
                  v-model="bigForm.name"
                  @input="limitInputlength(bigForm.name, 'bigForm.name')"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="剩余额度(元)" prop="usableAmount">
                <el-input
                  size="mini"
                  :disabled="true"
                  v-model="usableAmount"
                  @input="
                    limitInputlength(
                      bigForm.usableAmount,
                      'bigForm.usableAmount'
                    )
                  "
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 更多信息模块 -->
          <div class="navTitle">更多信息</div>
          <!-- 更多信息 -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="开户银行" prop="invoiceDepositBank">
                <el-input
                  size="mini"
                  v-model="bigForm.invoiceDepositBank"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="开户名称" prop="invoiceBankName">
                <el-input
                  size="mini"
                  v-model="bigForm.invoiceBankName"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="纳税人识别号" prop="invoiceLicenseNo">
                <el-input
                  size="mini"
                  v-model="bigForm.invoiceLicenseNo"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 更多信息第二行 -->

          <el-row>
            <el-col :span="8">
              <el-form-item label="邮编" prop="postalCode">
                <el-input
                  size="mini"
                  v-model="bigForm.postalCode"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户地址" prop="regionId">
                <el-cascader
                  size="mini"
                  :options="provinceList"
                  :props="defaultProp"
                  v-model="bigForm.regionId"
                  placeholder="请选择开户地址"
                  filterable
                  remote
                  reserve-keyword
                  clearable
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细地址" prop="address">
                <el-input
                  size="mini"
                  v-model="bigForm.address"
                  maxlength="80"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="navTitle">联系方式</div>
          <el-form-item prop="linkmanList" label-width="0">
            <div class="padR10 padL10">
              <el-table
                style="width: 100%"
                ref="dragTable"
                :data="bigForm.linkmanList"
                row-key="columnId"
                height="500"
                max-height="500"
                border
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
              >
                <el-table-column
                  align="center"
                  :label="tableCellLabel"
                  width="80"
                >
                  <template v-slot="scope">
                    <i
                      v-if="scope.row.hoverRow"
                      :disabled="disabled"
                      @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      v-if="scope.row.hoverRow"
                      :disabled="disabled"
                      @click="row('del', scope.$index)"
                      class="el-icon-remove operateDel"
                    ></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="联系人名称" width="180" align="center">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      class="contactName"
                      v-model="scope.row.linkMan"
                      maxlength="40"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column label="联系人电话" width="230" align="center">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      class="contactNo"
                      v-model="scope.row.linkManTel"
                      maxlength="20"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column label="联系人地址" align="center">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      class="contactAddress"
                      v-model="scope.row.linkAddress"
                      maxlength="80"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="备注" align="center">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      class="contactAddress"
                      v-model="scope.row.remark"
                      maxlength="80"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import { addCustomer } from '@/api/goods/customer.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { getAreaTree } from '@/api/goods/area.js'
import { listTree } from '@/api/goods/group.js'
import { getTenantEmployee } from '@/api/system/employee.js'
import { customerDetail, updateCustomer } from '@/api/goods/customer.js'
import { getAllRegion } from '@/api/goods/region'
import { priceCaseListAPI } from '@/api/system/goodsPrice/priceCase' //商品价格方案
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { limitInputlength } from '@/utils/index.js' //保留几位小数
import { listSettlementTerm } from "@/api/system/settlementTerm/index";//结算期限
export default {
  name: 'newCustomers',
  components: { Treeselect, cardTitleCom, topOperatingButton },
  dicts: ['common_status', 'pay_type'],
  data () {
    return {
      saleEmployeeList: [], //结算期限
      priceCaseList: [], //商品价格
      tableCellLabel: '序号',
      loading: false, //加载
      defaultProp: {
        value: 'id',
        label: 'label'
      }, //地址
      provinceList: [], //全部行政地区
      disabled: false,
      treeArea: [], //区域tree
      treeGroup: [], //分组tree
      dataList: [], //业务员

      bigForm: {
        linkmanList: [{ linkMan: '', linkManTel: '', linkAddress: '' }],
        //整体表单
        status: 0, //状态
        partnerType: 1, //客户类型
        payType: 0 //结算方式
      },
      //旧的表单
      oldForm: {},
      //整体表单
      rules: {
        invoiceDepositBank: [
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ], //开户银行
        invoiceBankName: [
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ], //开户名称
        invoiceLicenseNo: [
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ], //纳税人识别号
        postalCode: [{ pattern: /^.{1,20}$/, message: '请输入1 ~ 40个字符' }], //邮编

        address: [{ pattern: /^.{1,80}$/, message: '请输入1 ~ 80个字符' }], //详细地址

        partnerSimpleName: [
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ], //客户简称
        partnerName: [
          {
            required: true,
            message: '请输入客户名称',
            trigger: ['blur', 'change']
          },
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ],
        partnerNo: [
          {
            required: true,
            message: '请输入客户编码',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: '客户编码不能包含中文和空格',
            trigger: ['blur', 'change']
          }
        ],
        areaId: [
          {
            required: true,
            message: '请选择客户区域',
            trigger: 'input'
          }
        ],
        groupId: [
          {
            required: true,
            message: '请选择客户组别',
            trigger: 'input'
          }
        ]
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      //初始化数据
      vm.getTree() //渲染区域分组
      const res = await priceCaseListAPI({ pageNum: 1, pageSize: 100000 })
      vm.priceCaseList = res.rows
      // listSettlementTerm({pageNum: 1, pageSize: 100}).then(res => {
      //   vm.saleEmployeeList = res.rows
      // })
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.id) {
          //单据赋值
          vm.disabled = true //部分表单的禁用
          await vm.getDetail() //渲染列表
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.bigForm))
        }
      } else {
        vm.disabled = false
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.bigForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.bigForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave (to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.bigForm.partnerId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.bigForm))
    }
    next()
  },
  computed: {
    usableAmount () {
      if (this.bigForm.creditAmount) {
        this.bigForm.usableAmount =
          this.bigForm.creditAmount - this.bigForm.usedAmount
        return this.bigForm.creditAmount - this.bigForm.usedAmount
      }
    }
  },
  methods: {
    limitInputlength,
    //表格行hover时,显示操作加减号
    cellMouseEnter (row) {
      let eleIndex = this.bigForm.linkmanList.indexOf(row)
      for (let index = 0; index < this.bigForm.linkmanList.length; index++) {
        const element = this.bigForm.linkmanList[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave () {
      // 移除hover的事件
      for (let index = 0; index < this.bigForm.linkmanList.length; index++) {
        const element = this.bigForm.linkmanList[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //退出
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$router.push('/goods/partner/customer')
    },
    // 区域下拉框校验方法
    inputSelectArea () {
      this.$refs.bigForm.validateField('areaId')
    },
    // 组别下拉框校验方法
    inputSelectGroup () {
      this.$refs.bigForm.validateField('groupId')
    },
    // 表单重置
    reset () {
      this.bigForm = {
        linkmanList: [{ linkMan: '', linkManTel: '', linkAddress: '' }],
        //整体表单
        status: 0, //状态
        partnerType: 1, //客户类型
        payType: 0 //结算方式
      }
      this.resetForm('bigForm')
    },
    //获取区域和分组tree
    async getTree () {
      const res1 = await getAreaTree()
      this.treeArea = res1.data
      const res2 = await listTree()
      this.treeGroup = res2.data
      const res3 = await getTenantEmployee()
      this.dataList = res3.data
      //获取全部行政地区事件
      getAllRegion().then(response => {
        this.provinceList = response.data
      })
    },
    //下拉树结构数据转换
    normalizer (node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //表格增加/减少一行方法
    row (name, index) {
      if (name === 'push') {
        this.bigForm.linkmanList.splice(index + 1, 0, {})
      } else {
        if (this.bigForm.linkmanList.length === 1) {
          return
        }
        this.bigForm.linkmanList.splice(index, 1)
      }
    },

    /** 提交按钮 */
    submitForm: function () {
      if (this.bigForm.regionId) {
        this.bigForm.regionId =
          this.bigForm.regionId[this.bigForm.regionId.length - 1]
      }

      this.$refs['bigForm'].validate(async valid => {
        if (valid) {
          if (this.$route.query.id) {
            await updateCustomer(this.bigForm)
            this.$modal.msgSuccess('修改客户资料成功')
          } else {
            await addCustomer(this.bigForm)
            this.$modal.msgSuccess('新增客户资料成功')
          }
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              item => item.path === this.$route.path
            ),
            1
          )
          this.$router.push(
            this.$store.state.tagsView.visitedViews[
              this.$store.state.tagsView.visitedViews.length - 1
            ].path
          )
          this.$destroy()
        }
      })
    },

    //整体表单重置按钮
    cancel () {
      this.reset()
      this.$modal.msgSuccess('重置成功')
    },
    //获取客户资料详情
    async getDetail () {
      const res = await customerDetail(this.$route.query.id)
      this.bigForm = res.data
      if (this.bigForm.linkmanList.length === 0) {
        this.bigForm.linkmanList = [
          { linkMan: '', linkManTel: '', linkAddress: '' }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
}
//三个标题属性
.navTitle {
  font-weight: 700;
  line-height: 50px;
  height: 3%;
  border-bottom: 1px solid #e8eaf1;
  padding-left: 2%;
  margin-bottom: 2%;
}

//输入框属性
//下拉框属性
//地址属性
.el-input,
.el-cascader,
.el-select {
  width: 250px;
}

//联系人姓名
//联系人电话
//联系人地址和备注
.contactName,
.contactNo,
.contactAddress {
  width: 100%;
  align-items: center;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
::v-deep .vue-treeselect__control {
  height: 28px;
  line-height: 28px;
  width: 250px;
}
::v-deep .vue-treeselect__menu-container {
  height: 28px;
  line-height: 28px;
  width: 250px;
}
::v-deep .vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 28px;
}
::v-deep .vue-treeselect__single-value {
  line-height: 28px;
}
</style>
