<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form
            :model="queryParams"
            ref="queryForm"
            size="small"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
          >
            <div class="x-bc">
              <div>
                <el-form-item label="岗位编码" prop="postCode">
                  <el-input
                    v-model="queryParams.postCode"
                    placeholder="请输入岗位编码"
                    clearable
                    size="mini"
                    @keyup.enter.native="handleQuery"
                  />
                </el-form-item>
                <el-form-item label="岗位名称" prop="postName">
                  <el-input
                    v-model="queryParams.postName"
                    placeholder="请输入岗位名称"
                    clearable
                    size="mini"
                    @keyup.enter.native="handleQuery"
                  />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                  <el-select
                    v-model="queryParams.status"
                    placeholder="岗位状态"
                    clearable
                    size="mini"
                  >
                    <el-option
                      v-for="dict in dict.type.sys_normal_disable"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleQuery"
                    >查询</el-button
                  >
                  <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="岗位管理" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮   :multiple="multiple" -->
          <operatingButton
            :getList="getList"
            :isRecycleBtn="true"
            :isAddBtn="false"
            :isDeleteBtn="false"
            :isExportBtn="false"
            :isRefreshBtn="true"
            :multiple="multiple"
            @handleDelete="handleDelete"
            @handleRedoDelete="openPost = true"
          >
            <template #specialDiyBtn>
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                >新增</el-button
              ><!-- v-hasPermi="['system:post:add']" -->

              <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleUpdate"
                >修改</el-button
              ><!--  v-hasPermi="['system:post:edit']" -->

              <!-- <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                >删除</el-button
              >  
              v-hasPermi="['system:post:remove']" -->
            </template>
          </operatingButton>
          <!--  <el-button
                type="warning"
             
                icon="el-icon-download"
                size="mini"
                @click="handleExport"
                v-hasPermi="['system:post:export']"
                >导出</el-button
              > -->

          <el-table
            v-loading="loading"
            :data="postList"
            @row-click="handleRowClick"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            border
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="岗位编号" align="center" prop="postId" />
            <el-table-column label="岗位编码" align="center" prop="postCode" />
            <el-table-column label="岗位名称" align="center" prop="postName" />
            <el-table-column label="岗位排序" align="center" prop="postSort" />
            <el-table-column label="状态" align="center" prop="status">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.sys_normal_disable"
                  :value="scope.row.status"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
                  v-hasPermi="['system:post:edit']"
                  >修改</el-button
                >
                <!-- <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                  v-hasPermi="['system:post:remove']"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>

    <!-- <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->
    <c-dialog :title="title" :width="500" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="岗位名称" prop="postName">
            <el-input v-model="form.postName" placeholder="请输入岗位名称" />
          </el-form-item>
          <el-form-item label="岗位编码" prop="postCode">
            <el-input v-model="form.postCode" placeholder="请输入编码名称" />
          </el-form-item>
          <el-form-item label="岗位顺序" prop="postSort">
            <el-input-number
              v-model="form.postSort"
              controls-position="right"
              :min="0"
            />
          </el-form-item>
          <el-form-item label="岗位状态" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio
                v-for="dict in dict.type.sys_normal_disable"
                :key="dict.value"
                :label="dict.value"
                >{{ dict.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="请输入内容"
            />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="closeDia">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <selectPost
      :OpenBrand.sync="openPost"
      confirmText="还原"
      :refreshSearch="refreshSearch"
      @brandList="getBrandList"
    />
  </div>
</template>

<script>
import {
  listPost,
  getPost,
  delPost,
  addPost,
  updatePost,
  revertPost
} from '@/api/system/post'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import selectPost from '@/views/components/selectPost' //员工管理回收站弹窗
export default {
  name: 'Post',
  dicts: ['sys_normal_disable'],
  components: {
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage,
    CDialog,
    selectPost
  },
  data () {
    return {
      // 遮罩层
      loading: true,
      openPost: false, //回收站
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 岗位表格数据
      postList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        postCode: undefined,
        postName: undefined,
        status: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        postName: [
          { required: true, message: '岗位名称不能为空', trigger: 'blur' }
        ],
        postCode: [
          { required: true, message: '岗位编码不能为空', trigger: 'blur' }
        ],
        postSort: [
          { required: true, message: '岗位顺序不能为空', trigger: 'blur' }
        ]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
      //回收站
      refreshSearch: {
        pageNum: 1,
        pageSize: 15,
        delFlag: '1'
      }
    }
  },
  created () {
    this.getList()
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 134
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      console.log('wrap', this.vivwH)
      this.searchH = document.getElementById('search-card').clientHeight
      console.log('searchH', this.searchH)
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loading = true
        const res = await listPost({
          ...this.queryParams,

          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.postList = res.rows
        //表格多选
        this.postList.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loading = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    /** 查询岗位列表 */
    getList () {
      this.loading = true
      listPost(this.queryParams).then(response => {
        this.postList = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    //选择回收商品
    async getBrandList (val) {
      let postIdArr = val.map(v => v.postId)
      await revertPost(postIdArr)
      this.getList()
      this.$message({
        message: '还原成功',
        type: 'success'
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        postId: undefined,
        postCode: undefined,
        postName: undefined,
        postSort: 0,
        status: '0',
        remark: undefined
      }
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.postId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.title = '添加岗位'
    },
    /** 修改按钮操作 */
    handleUpdate (row) {
      this.reset()
      const postId = row.postId || this.ids
      getPost(postId).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改岗位'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.postId != undefined) {
            updatePost(this.form).then(response => {
              this.$modal.msgSuccess('修改成功')
              this.open = false
              this.getList()
            })
          } else {
            addPost(this.form).then(response => {
              this.$modal.msgSuccess('新增成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      // const postIds = row.postId || this.ids;
      const postIds = this.ids
      this.$modal
        .confirm('是否确认删除岗位编号为"' + postIds + '"的数据项？')
        .then(function () {
          return delPost(postIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        'system/post/export',
        {
          ...this.queryParams
        },
        `post_${new Date().getTime()}.xlsx`
      )
    },
    // 关闭弹窗
    closeDia () {
      this.open = false
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 100px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
</style>
