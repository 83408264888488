<template>
  <div class="wrap x-f" id="wrap" v-loading="loading">
    <cardTitleCom cardTitle="路线" class="headRoute marR10">
      <template slot="cardContent">
        <div class="tableContent">
          <el-table
            tooltip-effect="dark"
            :data="tableDataRoute"
            border
            v-loading="loadingRoute"
            @row-click="handleRowClick"
            @cell-mouse-enter="row => cellMouseEnter(row, 'tableDataRoute')"
            @cell-mouse-leave="cellMouseLeave('tableDataRoute')"
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column width="55" align="center">
              <template v-slot="scope">
                <el-radio v-model="scope.row.selected" :label="true">{{
                  ''
                }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="tableCellLabel1" width="80">
              <template v-slot="scope">
                <i
                  v-if="scope.row.hoverRow"
                  @click.stop="rowDetail('push', scope.$index)"
                  class="el-icon-circle-plus operatePush"
                ></i>
                <i
                  v-if="scope.row.hoverRow"
                  @click.stop="rowDetail('del', scope.$index, scope.row.lineId)"
                  class="el-icon-remove operateDel"
                ></i>
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="路线名称" align="center" prop="lineName">
              <template slot-scope="scope">
                <!-- <el-form-item> -->
                <el-input
                  size="mini"
                  v-model="scope.row.lineName"
                  @blur="blurRoute(scope.row)"
                />
                <!-- </el-form-item> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom
      :cardTitle="(routeDetail.lineName || '') + '配送对象'"
      class="headTarget marR10"
    >
      <template slot="cardContent">
        <div class="tableContent">
          <el-table
            tooltip-effect="dark"
            :data="tableDataTarget"
            border
            v-loading="loadingRouteAll"
            @cell-mouse-enter="row => cellMouseEnter(row, 'tableDataTarget')"
            @cell-mouse-leave="cellMouseLeave('tableDataTarget')"
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column align="center" :label="tableCellLabel2" width="80">
              <template v-slot="scope">
                <span
                  v-if="scope.row.hoverRow"
                  class="belonginDel"
                  @click="belongingRoute(scope.row, 'del')"
                  >移出</span
                >
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="编号" align="center" prop="no" />
            <el-table-column label="名称" align="center" prop="name" />
            <el-table-column label="类型" align="center" prop="type">
              <template slot-scope="scope">
                <span>{{ scope.row.type == 1 ? '客户' : '门店' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="所有配送对象" class="headTargetAll">
      <template #rightCardTitle>
        <selectAllFilter v-model="queryParams" :filters="filters" />
        <el-button
          class="marL10 marR10"
          type="primary"
          size="mini"
          @click="rightList('filter')"
          >查询</el-button
        >
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-table
            tooltip-effect="dark"
            :data="tableDataTargetFilter"
            border
            v-loading="loadingRouteAll"
            @cell-mouse-enter="
              row => cellMouseEnter(row, 'tableDataTargetFilter')
            "
            @cell-mouse-leave="cellMouseLeave('tableDataTargetFilter')"
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column align="center" :label="tableCellLabel3" width="80">
              <template v-slot="scope">
                <span
                  v-if="
                    scope.row.hoverRow &&
                    routeDetail.lineId != scope.row.deliverLineId
                  "
                  class="cursorP"
                  :style="{
                    color:
                      routeDetail.lineId === scope.row.deliverLineId
                        ? '#333333'
                        : '#1890ff',
                    cursor:
                      routeDetail.lineId === scope.row.deliverLineId
                        ? 'default'
                        : 'pointer'
                  }"
                  @click="belongingRoute(scope.row, 'add')"
                  >添加</span
                >
                <div
                  v-if="
                    !scope.row.hoverRow ||
                    routeDetail.lineId == scope.row.deliverLineId
                  "
                >
                  {{ scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="编号" align="center" prop="no" />
            <el-table-column label="名称" align="center" prop="name" />
            <el-table-column label="类型" align="center" prop="type">
              <template slot-scope="scope">
                <span>{{ scope.row.type == 1 ? '客户' : '门店' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="所属路线"
              align="center"
              prop="deliverLineName"
            />
          </el-table>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  queryDeliverLineList,
  deliverLineUpdate,
  queryDeliverLineDistributionList,
  deliverLineDelete,
  updateDeliverLineDistributionInfo
} from '@/api/system/partner/deliverLine' //配送路线
import selectAllFilter from '@/components/tablePage/select/select-all-filter/index.vue' //搜索
export default {
  name: 'deliverLine',
  components: {
    cardTitleCom,
    selectAllFilter
  },
  data () {
    return {
      filters: [
        { filter: 'query', label: '全部' },
        { filter: 'nos', label: '编号' },
        { filter: 'names', label: '名称' },
        { filter: 'deliverLineNames', label: '所属路线' }
      ],
      // 查询参数
      queryParams: {},
      tableCellLabel1: '序号',
      tableCellLabel2: '序号',
      tableCellLabel3: '序号',
      loadingRoute: false, //加载
      routeDetail: {}, //路线详情
      tableDataRoute: [
        {
          deliverLineId: Date.now()
        }
      ], //路线
      tableDataTarget: [], //对象
      loadingRouteAll: false, //筛选对象加载
      tableDataTargetAll: [], //所有对象
      tableDataTargetFilter: [], //筛选对象
      vivwH: 0 //页面高度
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - 70
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    console.log('h', this.vivwH)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    this.getList()
  },
  methods: {
    //刷新
    async rightList (val) {
      const res1 = await queryDeliverLineDistributionList(this.queryParams)
      this.tableDataTargetFilter = res1.data?.filter
      if (val != 'filter') {
        this.tableDataTargetAll = res1.data?.all
        this.tableDataTarget = this.tableDataTargetAll.filter(
          v => v.deliverLineId == this.routeDetail.lineId
        )
      }
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //添加路线
    async belongingRoute (row, valType) {
      //判断是不是相同路线
      if (valType === 'add' && this.routeDetail.lineId === row.deliverLineId)
        return
      try {
        this.loadingRouteAll = true
        if (!this.routeDetail.lineId) {
          if (!this.routeDetail.lineName) {
            this.loadingRouteAll = false
            this.$message.error('路线名称不能为空')
          }
          await this.blurRoute(this.routeDetail)
        }
        const data = [
          {
            deliverLineId: valType === 'add' ? this.routeDetail.lineId : '-1',
            id: row.id,
            type: row.type
          }
        ]
        await updateDeliverLineDistributionInfo(data)
        await this.rightList()
        const successMessage = valType === 'add' ? '添加成功' : '删除成功'
        this.$message.success(successMessage)
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.loadingRouteAll = false
      }
    },
    async getList () {
      if (!this.loading) {
        this.loading = true
      }
      try {
        const res = await queryDeliverLineList()
        this.tableDataRoute = res.data
        const res1 = await queryDeliverLineDistributionList(this.queryParams)
        this.tableDataTargetFilter = res1.data?.filter
        this.tableDataTargetAll = res1.data?.all
        if (this.tableDataRoute.length == 0) {
          this.tableDataRoute.push({
            deliverLineId: Date.now()
          })
        }
        //默认选中第一条
        this.handleRowClick(this.tableDataRoute[0])
        this.loading = false
      } catch (error) {
        console.error('Error fetching data:', error)
        this.tableDataRoute = [
          {
            deliverLineId: Date.now()
          }
        ]
        this.loading = false
      }
    },
    //生产组输入框失焦
    async blurRoute (row) {
      //判断编号、名称是否填写
      if (row.lineName) {
        const res = await deliverLineUpdate({
          lineName: row.lineName,
          lineId: row.lineId
        })
        this.$set(row, 'lineId', res?.data?.lineId)
        this.$message.success('保存成功！')
      }
    },
    //点击生产组当前行
    async handleRowClick (row) {
      this.routeDetail = row // 获取当前行的信息

      this.tableDataRoute.forEach(item => {
        if (
          item.lineId
            ? item.lineId == row.lineId
            : item.deliverLineId == row.deliverLineId
        ) {
          this.$set(item, 'selected', true)
        } else {
          this.$set(item, 'selected', false)
        }
      })

      this.tableDataTarget = this.tableDataTargetAll.filter(
        v => v.deliverLineId == row.lineId
      )
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter (row, table) {
      let eleIndex = this[table].indexOf(row)
      for (let index = 0; index < this[table].length; index++) {
        const element = this[table][index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      if (table == 'tableDataRoute') {
        this.tableCellLabel1 = '操作'
      } else if (table == 'tableDataTarget') {
        this.tableCellLabel2 = '操作'
      } else if (table == 'tableDataTargetFilter') {
        this.tableCellLabel3 = '操作'
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave (table) {
      // 移除hover的事件
      for (let index = 0; index < this[table].length; index++) {
        const element = this[table][index]
        element['hoverRow'] = false
      }
      if (table == 'tableDataRoute') {
        this.tableCellLabel1 = '序号'
      } else if (table == 'tableDataTarget') {
        this.tableCellLabel2 = '序号'
      } else if (table == 'tableDataTargetFilter') {
        this.tableCellLabel3 = '序号'
      }
    },
    //原料明细表格当中增加/减少一行方法
    rowDetail (name, index, lineId) {
      if (name === 'push') {
        this.tableDataRoute.splice(index + 1, 0, { deliverLineId: Date.now() })
      } else {
        if (this.tableDataRoute.length == 1 && !this.tableDataRoute[0].lineId)
          return
        if (this.tableDataTarget.length > 0)
          return this.$message.error('请先删除当前路线下的所有对象')
        this.$confirm('您确认要删除选中的路线吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            if (lineId) {
              await deliverLineDelete([lineId])
              await this.getList()
            } else {
              this.tableDataRoute.splice(index, 1)
            }
          })
          .catch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  width: 100%;
  height: calc(100vh - 84px);
  .headRoute {
    width: 25%;
  }
  .headTarget,
  .headTargetAll {
    width: 37.3%;
  }
  .tableContent {
    padding: 6px 10px 10px 10px;
  }
  .belonginDel {
    cursor: pointer;
    color: #ff3753;
  }
  .belonginDel:hover {
    color: #ff4355;
  }
}
</style>
