<template>
  <!-- =====员工管理======= -->
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard
      categoryTreeTitle="员工管理"
      @treeNodeId="getTreeNodeId"
      :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight"
      :categoryTreeOptions="deptOptions"
      :isVoluntarily="false"
    />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <el-form
              :model="queryParams"
              ref="queryForm"
              size="small"
              :inline="true"
              v-show="showSearch"
              label-width="68px"
            >
              <div class="x-b">
                <div>
                  <el-form-item label="员工姓名" prop="employeeName">
                    <el-input
                      v-model="queryParams.employeeName"
                      placeholder="请输入员工姓名"
                      clearable
                      size="mini"
                      @keyup.enter.native="handleQuery"
                    />
                  </el-form-item>
                  <el-form-item label="员工编号" prop="employeeNo">
                    <el-input
                      v-model="queryParams.employeeNo"
                      placeholder="请输入员工编号"
                      clearable
                      size="mini"
                      @keyup.enter.native="handleQuery"
                    />
                  </el-form-item>
                  <el-form-item label="手机号码" prop="telephone">
                    <el-input
                      v-model="queryParams.telephone"
                      placeholder="请输入手机号码"
                      clearable
                      size="mini"
                      @keyup.enter.native="handleQuery"
                    />
                  </el-form-item>
                  <el-form-item label="状态" prop="status">
                    <el-select
                      v-model="queryParams.status"
                      placeholder="请选择状态"
                      clearable
                      size="mini"
                    >
                      <el-option label="正常" value="0"></el-option>
                      <el-option label="停用" value="1"></el-option>
                      <el-option label="删除" value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item label="审核状态" prop="auditStatus">
                                                <el-select
                                                  v-model="queryParams.auditStatus"
                                                  placeholder="请选择审核状态"
                                                  clearable
                                                >
                                                  <el-option
                                                    v-for="dict in dict.type.audit_status"
                                                    :key="dict.value"
                                                    :label="dict.label"
                                                    :value="dict.value"
                                                  />
                                                </el-select>
                                              </el-form-item> -->
                </div>
                <div class="big">
                  <el-form-item>
                    <el-button type="primary" size="mini" @click="handleQuery"
                      >查询
                    </el-button>
                    <el-button
                      icon="el-icon-refresh"
                      size="mini"
                      @click="resetQuery"
                      >重置
                    </el-button>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="员工管理" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <operatingButton
              :getList="getList"
              :isExportBtn="true"
              :isRecycleBtn="true"
              :isDeleteBtn="false"
              :multiple="multiple"
              @handleAdd="handleAdd"
              @handleDelete="handleDelete"
              @handleRedoDelete="openBrand = true"
            >
              <template #specialDiyBtn>
                <el-dropdown
                  split-button
                  size="mini"
                  icon="el-icon-circle-check"
                  class="startUsing"
                  @click="handleIsStop(false)"
                  @command="handleIsStop(true)"
                >
                  启用
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="禁用">禁用</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  class="startUsing"
                  v-hasPermi="['system:employee:list:edit']"
                  :disabled="single"
                  @click="handleAuthRole"
                  >分配角色
                </el-button>

                <!-- <el-button
                  type="info"
                  icon="el-icon-refresh"
                  size="mini"
                  :disabled="multiple"
                  @click="handleComeBack"
                  >还原
                </el-button> -->
                <!-- <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :disabled="single"
                  @click="handleDelete"
                  >删除
                </el-button> -->
                <!-- v-hasPermi="['system:employee:remove']" -->
                <!-- <el-button
                                type="warning"
                                icon="el-icon-download"
                                size="mini"
                                @click="handleExport"
                                >导出
                              </el-button> -->
                <!-- v-hasPermi="['system:employee:export']" -->
              </template>
            </operatingButton>
            <el-table
              v-loading="loadingTable"
              :data="employeeList"
              @selection-change="handleSelectionChange"
              border
              @row-click="handleRowClick"
              ref="multipleTable"
              ::max-height="tableHeight"
              :height="tableHeight"
            >
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column
                label="员工编号"
                align="center"
                key="employeeNo"
                prop="employeeNo"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    @click.stop="handleUpdate(scope.row)"
                    target="_blank"
                  >
                    {{ scope.row.employeeNo }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                label="员工姓名"
                align="center"
                key="employeeName"
                prop="employeeName"
              />
              <el-table-column label="员工状态" align="center" key="status">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">正常</span>
                  <span v-if="scope.row.status == 1">禁用</span>
                </template>
              </el-table-column>
              <el-table-column
                label="所在部门"
                align="center"
                key="deptName"
                prop="dept.deptName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="手机号码"
                align="center"
                key="telephone"
                prop="telephone"
                width="120"
              />
              <el-table-column
                label="用户账户"
                align="center"
                key="userName"
                prop="userName"
              />
              <!-- <el-table-column label="审核状态" align="center" prop="auditStatus">
                                        <template slot-scope="scope">
                                          <dict-tag
                                            :options="dict.type.audit_status"
                                            :value="scope.row.auditStatus"
                                          />
                                        </template>
                                      </el-table-column> -->
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
                width="160"
              >
                <template slot-scope="scope">
                  <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
              </el-table-column>
              <!--              <el-table-column-->
              <!--                label="操作"-->
              <!--                align="center"-->
              <!--                width="180"-->
              <!--                class-name="small-padding fixed-width"-->
              <!--              >-->
              <!--                <template slot-scope="scope">-->
              <!--                  <el-button-->
              <!--                    size="mini"-->
              <!--                    type="text"-->
              <!--                    icon="el-icon-delete"-->
              <!--                    v-hasPermi="['system:employee:authRole']"-->
              <!--                    @click="handleAuthRole(scope.row)"-->
              <!--                  >分配角色&lt;!&ndash;  v-hasPermi="['system:employee:edit']" &ndash;&gt;-->
              <!--                  </el-button>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :idsCom="ids"
              :totalCom="total"
              @selectAllCom="selectAll"
            />
          </div>
        </template>
      </cardTitleCom>
    </div>

    <!-- 添加或修改员工配置对话框(加上标签v-dialogDrag="true"可以移动窗口) -->
    <c-dialog :title="title" :width="640" :showDialog.sync="open">
      <template #content>
        <el-form
          ref="form"
          :model="formData"
          inline
          :rules="rules"
          label-width="80px"
          v-loading="loadingDialog"
        >
          <el-form-item label="员工编号" prop="employeeNo">
            <el-input
              class="w200"
              size="mini"
              :disabled="formData.isInit && dialogType === 'edit'"
              v-model="formData.employeeNo"
              placeholder="请输入员工编号"
              maxlength="30"
            />
          </el-form-item>
          <el-form-item label="员工姓名" prop="employeeName">
            <el-input
              size="mini"
              class="w200"
              v-model="formData.employeeName"
              placeholder="请输入员工姓名"
              maxlength="30"
            />
          </el-form-item>
          <el-form-item label="手机号码" prop="telephone">
            <el-input
              class="w200"
              size="mini"
              v-model="formData.telephone"
              placeholder="请输入手机号码"
              maxlength="11"
            />
          </el-form-item>
          <el-form-item
            v-if="dialogType === 'add'"
            label=" "
            label-width="2"
            prop="authCode"
            class="x-f marL30"
          >
            <el-input
              style="width: 120px"
              size="mini"
              v-model="formData.authCode"
              placeholder="请输入验证码"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @input="findByVerifyCode"
              maxlength="11"
            />
            <el-button
              size="mini"
              class="marL10"
              type="primary"
              :disabled="isDisabled"
              @click="getMessage"
              >{{ messageInfo }}
            </el-button>
          </el-form-item>
          <el-form-item
            label="密码"
            prop="password"
            v-if="showPassword && dialogType === 'add'"
          >
            <el-input
              class="w200"
              size="mini"
              v-model="formData.password"
              @input="formData.password = formData.password.trim()"
              auto-complete="new-password"
              show-password
              placeholder="请输入密码"
              maxlength="18"
            />
          </el-form-item>
          <el-form-item
            label="确认密码"
            prop="newPassword"
            v-if="showPassword && dialogType === 'add'"
          >
            <el-input
              class="w200"
              size="mini"
              v-model="formData.newPassword"
              @input="formData.newPassword = formData.newPassword.trim()"
              auto-complete="new-password"
              show-password
              placeholder="请输入确认密码"
              maxlength="18"
            />
          </el-form-item>
          <el-form-item label="归属部门" prop="deptId">
            <treeselect
              class="w200"
              size="mini"
              v-model="formData.deptId"
              :options="deptOptions"
              :show-count="true"
              placeholder="请选择归属部门"
            />
          </el-form-item>
          <el-form-item label="岗位" prop="postIds">
            <el-select
              class="w200"
              size="mini"
              v-model="formData.postIds"
              multiple
              placeholder="请选择岗位"
            >
              <el-option
                v-for="item in postOptions"
                :key="item.postId"
                :label="item.postName"
                :value="item.postId"
                :disabled="+item.status === 1"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色" prop="roleIds">
            <el-select
              class="w200"
              size="mini"
              v-model="formData.roleIds"
              multiple
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in roleOptions"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId"
                :disabled="+item.status === 1"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              class="w200"
              size="mini"
              v-model="formData.remark"
              type="textarea"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button size="mini" @click="cancel">取 消</el-button>
      </template>
    </c-dialog>

    <selectEmployee
      :OpenBrand.sync="openBrand"
      confirmText="还原"
      :refreshSearch="refreshSearch"
      @brandList="getBrandList"
    />
    <!-- 审核对话框 -->
    <!-- <el-dialog
          :title="auditTitle"
          :visible.sync="auditOpen"
          width="600px"
          append-to-body
        >
          <el-form ref="form" :model="auditForm" :rules="rules" label-width="160px">
            <el-form-item label="员工姓名" prop="employeeName">
              <el-input v-model="auditForm.employeeName" :disabled="true" />
            </el-form-item>
            <el-form-item label="员工编号" prop="employeeNo">
              <el-input v-model="auditForm.employeeNo" />
            </el-form-item>
            <el-form-item label="审核状态" prop="auditStatus">
              <el-select
                v-model="auditForm.auditStatus"
                placeholder="请选择审核状态"
                clearable
              >
                <el-option
                  v-for="dict in dict.type.audit_status"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="审核意见" prop="suggest">
              <el-input
                type="textarea"
                v-model="auditForm.suggest"
                placeholder="请输入审核意见"
              />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitAuditForm">确 定</el-button>
            <el-button @click="cancelAudit">取 消</el-button>
          </div>
        </el-dialog> -->
  </div>
</template>

<script>
import {
  listEmployee,
  getEmployee,
  delEmployee,
  addEmployee,
  updateEmployee,
  auditEmployee,
  revertAPI,
  uinitPasswordAPI,
  changeUserStatus,
  revertEmpoloyee
} from '@/api/system/employee'
import { treeselect } from '@/api/system/dept'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { resetUserPwd, sendCode, smsVerifyCode } from '@/api/system/user'
import { isStopCoupon } from '@/api/marketing/coupon/case'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectEmployee from '@/views/components/selectEmployee' //员工管理回收站弹窗

import { environment, validateUserTelephone } from '@/api/login'
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import operatingButton from '@/views/components/operatingButton' //操作按钮
import CDialog from '@/components/dialogTemplate/dialog/index.vue'

export default {
  name: 'Employee',
  dicts: ['audit_status'],
  components: {
    Treeselect,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    operatingButton,
    selectEmployee,
    CDialog
  },
  data () {
    const validatePass2 = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error('请输入登录密码'))
      } else if (this.formData.password !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    // 手机校验
    let checkTel = (rule, value, callback) => {
      let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/

      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else if (!this.formData.employeeId) {
        validateUserTelephone({
          telephone: this.formData.telephone
        }).then(res => {
          this.showPassword = res.status != 'join' && res.status != 'register'
          if (res.data) {
            callback(new Error('用户已经加入该商户'))
          } else {
            callback()
          }
        })
      } else {
        callback()
      }
    }

    return {
      // isSubmit: false, // 是否可提交
      openBrand: false, //回收站弹窗
      dialogType: '', // 添加编辑字符串标记
      // 遮罩层
      loading: true,
      //表格
      loadingTable: false,
      //弹窗
      loadingDialog: false,
      // 选中数组 ID
      ids: [],
      // 选中数组
      selectData: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户表格数据
      employeeList: [],
      // 弹出层标题
      title: '',
      // 是否需要输入密码
      showPassword: false,
      // 审核标题
      auditTitle: undefined,
      // 审核窗口打开对话框开关
      auditOpen: false,
      // 部门树选项
      deptOptions: undefined,
      // 是否显示弹出层
      open: false,
      // 部门名称
      deptName: undefined,

      // 日期范围
      dateRange: [],
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      formData: {
        employeeId: undefined,
        employeeNo: '',
        employeeName: '',
        telephone: '',
        auditStatus: null,
        password: '',
        newPassword: '',
        authCode: '',
        roleIds: []
      },
      isSend: true, // 是否发送过短信
      // 发送短信验证码按钮文本
      messageInfo: '发送验证码',
      isDisabled: false, // 是否可点击
      isAuthCode: false, // 验证码是否正确
      currentEnvironment: '', // 当前环境标识
      // 审核表单参数
      // auditForm: {
      //     employeeId: undefined,
      //     employeeNo: '',
      //     employeeName: '',
      //     telephone: '',
      //     auditStatus: null,
      //     suggest: null
      // },

      defaultProps: {
        children: 'children',
        label: 'label'
      },

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        employeeNo: '',
        employeeName: '',
        telephone: '',
        auditStatus: null,
        deptId: null
      },
      // 列信息
      columns: [
        { key: 0, label: `用户编号`, visible: true },
        { key: 1, label: `用户名称`, visible: true },
        { key: 2, label: `用户昵称`, visible: true },
        { key: 3, label: `部门`, visible: true },
        { key: 4, label: `手机号码`, visible: true },
        { key: 5, label: `状态`, visible: true },
        { key: 6, label: `创建时间`, visible: true }
      ],
      // 表单校验
      rules: {
        employeeName: [
          { required: true, message: '员工姓名不能为空', trigger: 'blur' },
          {
            min: 2,
            max: 20,
            message: '员工姓名长度必须介于 2 和 20 之间',
            trigger: 'blur'
          }
        ],
        employeeNo: [
          { required: true, message: '员工编号不能为空', trigger: 'blur' }
        ],
        telephone: [
          { required: true, validator: checkTel, trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入6到18位登录密码' },
          {
            min: 6,
            max: 18,
            message: '请输入6到18位登录密码',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: '密码不能包含中文',
            trigger: ['blur', 'change']
          }
        ],
        newPassword: [
          {
            required: true,
            message: '请确认密码',
            trigger: ['blur', 'change']
          },
          {
            min: 6,
            max: 18,
            message: '请输入6到18位登录密码',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: '密码不能包含中文',
            trigger: ['blur', 'change']
          },
          {
            validator: validatePass2,
            trigger: ['blur', 'change'],
            required: true
          }
        ],
        authCode: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' }
        ],
        roleIds: [{ required: true, message: '请选择角色', trigger: 'change' }]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
      //回收站
      refreshSearch: {
        pageNum: 1,
        pageSize: 15,
        delFlag: '1'
      }
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight () {
      return this.vivwH - 10
    },
    boxInternalHeight () {
      return this.vivwH - 83
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    // 根据名称筛选部门树
    deptName (val) {
      this.$refs.tree.filter(val)
    },
    'form.telephone': {
      handler (nVal) {
        // console.log("telephone-----", nVal);
        this.isDisabled = nVal === '' && this.currentEnvironment !== 'prod'
      }
    }
  },
  created () {
    this.loading = true
    this.getEnvironment()
    this.getList()
    this.getTreeselect()
    this.loading = false
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    /** 查询员工列表 */
    getList () {
      this.loadingTable = true
      listEmployee(this.addDateRange(this.queryParams, this.dateRange)).then(
        response => {
          this.employeeList = response.rows
          this.total = response.total
          this.loadingTable = false
        }
      )
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await listEmployee({
          ...this.queryParams,
          ...this.dateRange,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.employeeList = res.rows
        //表格多选
        this.employeeList.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //点击类别树
    getTreeNodeId (value) {
      this.queryParams.deptId = value
      this.getList()
    },
    // 发送短信验证码
    getMessage () {
      if (this.currentEnvironment !== 'prod') return
      console.log('手机号：', this.formData.telephone)
      if (this.formData.telephone === '' || !this.formData.telephone) {
        return this.$message.warning('请先输入手机号')
      }
      this.isSend = true // 标记已发送过短信
      let time = 60
      this.messageInfo = time + '秒后重试'
      sendCode(this.formData.telephone)
      let timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.messageInfo = '获取验证码'
          this.isDisabled = false
        } else {
          this.messageInfo = time + '秒后重试'
          this.isDisabled = true
          time--
        }
      }, 1000)
    },
    // 验证验证码是否正确
    findByVerifyCode (val) {
      if (val.length === 6) {
        smsVerifyCode({
          code: val,
          telephone: this.formData.telephone
        }).then(res => {})
      }
    },
    /** 查询部门下拉树结构 */
    getTreeselect () {
      treeselect().then(response => {
        this.deptOptions = response.data
      })
    },
    // 筛选节点
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 节点单击事件
    handleNodeClick (data) {
      this.queryParams.deptId = data.id
      this.handleQuery()
    },

    // 取消按钮
    cancel () {
      this.open = false
      this.$refs.form.resetFields()
      this.reset()
    },

    // 取消按钮
    cancelAudit () {
      this.auditOpen = false
      this.reset()
    },

    // 表单重置
    reset () {
      this.formData = {
        password: '',
        newPassword: '',
        employeeId: undefined,
        deptId: undefined,
        employeeNo: undefined,
        employeeName: '',
        telephone: undefined,
        email: undefined,
        sex: undefined,
        status: '0',
        auditStatus: undefined,
        remark: undefined,
        postIds: [],
        roleIds: []
      }
      console.log(this.formData)
      this.resetForm('formData')
    },
    //选择回收商品
    async getBrandList (val) {
      let employeeArr = val.map(v => v.employeeId)
      await revertEmpoloyee(employeeArr)
      this.getList()
      this.$message({
        message: '还原成功',
        type: 'success'
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },

    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.resetForm('queryForm')
      this.handleQuery()
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.employeeId)
      this.selectData = selection
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd () {
      this.dialogType = 'add'
      this.reset()
      this.open = true
      getEmployee().then(response => {
        this.postOptions = response.posts
        this.roleOptions = response.roles
        this.formData.postIds = response.postIds
        this.formData.roleIds = response.roleIds

        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
        // console.log(this.formData)
        this.title = '添加员工'

        console.log(this.queryParams.deptId, this.deptOptions[0].id)
        this.formData.deptId = !this.queryParams.deptId
          ? this.deptOptions[0].id
          : this.queryParams.deptId
      })
    },

    /** 修改按钮操作 */
    handleUpdate (row) {
      // if (row.isInit) {
      //   return this.$message.warning('该员工不允许修改')
      // }
      try {
        this.loadingDialog = true
        this.dialogType = 'edit'
        this.reset()
        this.open = true
        getEmployee(row.employeeId).then(response => {
          this.formData = response.data
          this.formData.password = ''
          this.formData.newPassword = ''
          // this.formData.employeeName = ''
          this.postOptions = response.posts
          this.roleOptions = response.roles
          this.formData.postIds = response.postIds
          this.formData.roleIds = response.roleIds
          console.log('=', this.formData)
          this.title = '修改员工'
          this.loadingDialog = false
        })
      } catch (error) {
        this.loadingDialog = false
      }
    },

    /** 分配角色操作 */
    handleAuthRole () {
      const employeeId = this.selectData[0].employeeId
      this.$router.push('/system/user-auth/role/' + employeeId)
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.formData.employeeId) {
            updateEmployee(this.formData).then(response => {
              this.$modal.msgSuccess('修改成功')
              this.open = false
              this.getList()
            })
          } else {
            addEmployee(this.formData).then(response => {
              this.$modal.msgSuccess('新增成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete (row) {
      const employeeIds = this.selectData
        .map(item => {
          if (!item.isInit && !item.isTenantAdmin) {
            return item.employeeId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      if (employeeIds.length == 0)
        return this.$message.warning('当前选中用户不可删除')
      this.$modal
        .confirm('您确认要删除选中的用户吗？')
        .then(function () {
          return delEmployee(employeeIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    },

    handleComeBack () {
      const selectedData = this.employeeList.filter(item =>
        this.ids.includes(item.employeeId)
      )

      // 检查是否有未删除状态的数据
      const hasUndeletedData = selectedData.some(item => item.delFlag === '0')

      if (hasUndeletedData) {
        // 提示未删除状态，不需要还原
        this.$modal.msgWarning('选中的数据包含未删除状态，不需要还原')
        return
      }

      const employeeIds = this.ids
      this.$modal
        .confirm(
          '是否确认还原用户编号为"' + employeeIds.join(', ') + '"的数据项？'
        )
        .then(() => {
          return revertAPI(employeeIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('还原成功')
        })
        .catch(() => {})
    },

    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/role/export',
        {
          ...this.queryParams
        },
        `user_${new Date().getTime()}.xlsx`
      )
    },
    //是否启用/停用事件
    handleIsStop (bool) {
      //bool  false是启用 true是停用
      let ids = this.selectData
        .map(item => {
          //是否是商户管理员
          if (!item.isTenantAdmin) {
            return item.employeeId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      if (ids.length == 0)
        return this.$message.warning('当前选中用户不可启用或禁用')
      let status = 0
      if (!bool) {
        // 启用
        status = 0
      } else {
        // 禁用
        status = 1
      }
      changeUserStatus(ids, status).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    // 获取当前环境
    getEnvironment () {
      environment().then(res => {
        this.currentEnvironment = res.data
        if (this.currentEnvironment !== 'prod') {
          this.isDisabled = true
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.startUsing {
  margin-left: 10px;
  // margin-right: 10px;

  ::v-deep.el-button-group > .el-button {
    background-color: #ffba00 !important;
    color: #fff;
  }

  ::v-deep.el-button-group > .el-button:hover {
    background-color: #f9a13a !important;
  }
}

//整体盒子
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      margin-bottom: 6px;
      // 搜索
      .navSearch {
        padding: 10px 0px 0px 10px;
      }
    }

    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;

      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}

//新增修改内部标题
.inforTitle {
  font-weight: 700;
  margin: 0 0 15px 10px;
  font-size: 16px;
}

::v-deep .vue-treeselect__control {
  width: 200px;
}

//treeselect
::v-deep .vue-treeselect__menu-container {
  width: 250px;
}

//treeselect
.select {
  width: 200px;
}

.w200 {
  width: 200px;
}

.big {
  width: 168px;
}
</style>
