<template>
  <!-- 整体内容 -->
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard categoryTreeTitle="客供分组" @treeNodeId="getTreeNodeId" @appendTree="appendTree" @editTree="editTree"
      @removeTree="removeTree" :isEditBtn="true" :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight"
      :categoryTreeOptions="groupTreeOptions" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList"
              :isAdvancedSearch="false" :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入组别编码、名称" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="单据信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
              @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
            <div>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange" border v-loading="loadingTable" @row-click="handleRowClick"
                :max-height="tableHeight" :height="tableHeight">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column align="center" label="序号" width="80" type="index">
                </el-table-column>
                <el-table-column prop="groupNo" label="组别编码" show-overflow-tooltip width="120" align="center">
                  <template slot-scope="scope">
                    <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                      {{ scope.row.groupNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="groupName" label="组别名称" show-overflow-tooltip width="120" align="center">
                </el-table-column>

                <el-table-column prop="zjm" label="助记码" show-overflow-tooltip width="120" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" show-overflow-tooltip align="center">
                </el-table-column>
                <el-table-column prop="updateBy" label="修改人" show-overflow-tooltip width="120" align="center">
                </el-table-column>
                <el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip width="155" align="center">
                </el-table-column>
                <el-table-column prop="createBy" label="创建人" show-overflow-tooltip width="120" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip width="155" align="center">
                </el-table-column>
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 添加修改区域对话框 -->
    <c-dialog :title="title" :width="500" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" v-loading="loadingDia">
          <el-form-item label="上级分组" prop="parentId">
            <treeselect v-model="form.parentId" :options="groupTreeOptions" :show-count="true" placeholder="请选择上级分组"
              :normalizer="normalizer" @input="inputSelect" />
          </el-form-item>
          <el-form-item label="组别编码" prop="groupNo">
            <el-input v-model="form.groupNo" placeholder="请输入组别编码" />
          </el-form-item>
          <el-form-item label="组别名称" prop="groupName">
            <el-input v-model="form.groupName" placeholder="请输入组别名称" />
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" type="textarea" placeholder="备注长度介于 1 和 30 字符之间" maxlength="30"
              :autosize="{ minRows: 2, maxRows: 4 }" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  listTree,
  listGroup,
  getGroupDetail,
  addGroup,
  updateGroup,
  delGroup,
  restorePartnerGroup
} from '@/api/goods/group'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'partnerGroup',
  components: {
    Treeselect,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    CDialog,
    Dialog2
  },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listGroup,
          restoreListApi: restorePartnerGroup,
          id: 'groupId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'areaNos', label: '组别编码' },
                { filter: 'areaNames', label: '组别名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'groupNo',
              label: '组别编码',
              minWidth: 180
            },
            {
              prop: 'groupName',
              label: '组别名称',
              minWidth: 180
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 100
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      //tree选中的id
      selectTreeId: undefined,
      loadingDia: false,
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'areaNos', name: '组别编码' },
        { parameter: 'areaNames', name: '组别名称' }
      ],
      loadingTable: false, //表格加载
      loading: false, //加载框
      single: true, //编辑的禁用
      multiple: true, //删除的禁用

      // 弹出层标题
      title: '',

      // 选中数组
      ids: [],
      //选中数组的名称
      name: [],

      // 总条数
      total: 0,

      // 是否显示弹出层
      open: false,

      // 分类表格数据
      tableData: null,

      // 左边树装选择内容
      groupTreeOptions: [],

      // 表单参数
      form: {
        groupId: undefined,
        groupNo: undefined,
        groupName: undefined,
        parentId: undefined,
        remark: undefined
      },

      // 表单校验
      rules: {
        groupNo: [
          { required: true, message: '组别编码不能为空', trigger: 'blur' },
          { pattern: /^[0-9]{1,20}$/, message: '请输入1 ~ 20个数值类型的字符' }
        ],
        groupName: [
          { required: true, message: '组别名称不能为空', trigger: 'blur' },
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ],
        parentId: [
          { required: true, message: '父级ID不能为空', trigger: 'input' }
        ]
      },

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        groupNo: undefined,
        groupName: undefined,
        parentId: undefined
      },
      //表格选中内容
      multipleSelection: [],
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight() {
      return this.vivwH - 10
    },
    boxInternalHeight() {
      return this.vivwH - 83
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  async created() {
    this.loading = true
    await this.getList()
    await this.getTreeselect()
    this.loading = false
  },
  watch: {
    open(val) {
      if (!val) {
        this.$refs.form.resetFields()
      }
    }
  },
  methods: {
    //还原刷新页面
    async handleEvent() {
      this.loading = true
      await this.getList()
      await this.getTreeselect()
      this.loading = false
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listGroup({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击类别树
    getTreeNodeId(value) {
      this.selectTreeId = value
      this.queryParams.parentId = value
      this.getList()
    },
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //  修改\点击编号
    async handleDetail(row) {
      this.open = true
      this.loadingDia = true
      this.reset()
      this.getTreeselect()

      getGroupDetail(row.groupId).then(response => {
        this.form = response.data
        this.form.parentId = response.data.parentId
        this.title = '修改客供分组'
        this.loadingDia = false
      })
    },
    //新增Tree用户组
    appendTree(data) {
      this.resetForm('form')
      this.form = {
        parentId: data.id,
        groupId: null,
        groupNo: null,
        groupName: null,
        remark: null
      }
      this.title = '新增客供分组'
      this.open = true
      this.getTreeselect()
    },
    //编辑Tree用户组
    editTree(data) {
      this.open = true
      this.loadingDia = true
      this.reset()
      this.getTreeselect()
      const groupId = data.id
      getGroupDetail(groupId).then(response => {
        this.form = response.data
        this.title = '修改客供分组'
        this.loadingDia = false
      })
    },

    //删除Tree用户组
    removeTree({ node, data }) {
      this.$confirm(`确定删除(${data.label})客供分组,是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(() => {
          if (node.data.children) {
            this.$message({
              howClose: true,
              message: '请先删除子级客供分组',
              type: 'warning'
            })
            return
          }
          var Ids = [data.id]
          delGroup(Ids).then(response => {
            this.$modal.msgSuccess('删除成功')
            this.getList()
            this.getTreeselect()
          })
        })
        .catch()
    },
    // 下拉框校验方法
    inputSelect() {
      this.$refs.form.validateField('parentId')
    },

    /** 查询分类列表 */
    getList() {
      this.loadingTable = true
      listGroup(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loadingTable = false
      })
    },

    /** 查询分类下拉树结构 */
    getTreeselect() {
      listTree().then(response => {
        this.groupTreeOptions = response.data
      })
    },

    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },

    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },

    // 表单重置
    reset() {
      this.form = {
        parentId: null,
        groupName: null,
        groupNo: null,
        zjm: null,
        remark: null
      }
      this.resetForm('form')
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.groupId)
      this.name = selection.map(item => item.groupName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.open = true
      this.resetForm('form')
      this.form = {
        parentId: this.selectTreeId,
        groupName: null,
        groupNo: null,
        zjm: null,
        remark: null
      }
      this.title = '新增客供分组'
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.groupId == this.form.parentId) {
            this.$message.error('父类不能和子类相同')
            return
          }
          if (this.form.groupId != null) {
            updateGroup(this.form).then(response => {
              this.$modal.msgSuccess('修改客供分组成功')
              this.open = false
              this.getList()
              this.getTreeselect()
            })
          } else {
            addGroup(this.form).then(response => {
              this.$modal.msgSuccess('新增客供分组成功')
              this.open = false
              this.getList()
              this.getTreeselect()
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const groupIds = row?.groupId || this.ids
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${groupIds.length} </span>个客供分组吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(function () {
          return delGroup(groupIds)
        })
        .then(() => {
          this.$modal.msgSuccess('删除成功')
          this.getList()
          this.getTreeselect()
        })
        .catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
        //筛选条件
        .searchCriteria {
          padding-top: 10px;
          .selectFrameUp {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}
::v-deep .vue-treeselect__control {
  width: 280px;
}

::v-deep .vue-treeselect__menu-container {
  width: 280px;
}
</style>
