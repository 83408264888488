var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "员工管理",
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.deptOptions,
          isVoluntarily: false,
        },
        on: { treeNodeId: _vm.getTreeNodeId },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c(
                      "el-form",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showSearch,
                            expression: "showSearch",
                          },
                        ],
                        ref: "queryForm",
                        attrs: {
                          model: _vm.queryParams,
                          size: "small",
                          inline: true,
                          "label-width": "68px",
                        },
                      },
                      [
                        _c("div", { staticClass: "x-b" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "员工姓名",
                                    prop: "employeeName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入员工姓名",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.employeeName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "employeeName",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.employeeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "员工编号",
                                    prop: "employeeNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入员工编号",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.employeeNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "employeeNo",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.employeeNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "手机号码",
                                    prop: "telephone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入手机号码",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.telephone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "telephone",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.telephone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态", prop: "status" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择状态",
                                        clearable: "",
                                        size: "mini",
                                      },
                                      model: {
                                        value: _vm.queryParams.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.status",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "正常", value: "0" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "停用", value: "1" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "删除", value: "2" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "big" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: { click: _vm.handleQuery },
                                    },
                                    [_vm._v("查询 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-refresh",
                                        size: "mini",
                                      },
                                      on: { click: _vm.resetQuery },
                                    },
                                    [_vm._v("重置 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "员工管理" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c("operatingButton", {
                      attrs: {
                        getList: _vm.getList,
                        isExportBtn: true,
                        isRecycleBtn: true,
                        isDeleteBtn: false,
                        multiple: _vm.multiple,
                      },
                      on: {
                        handleAdd: _vm.handleAdd,
                        handleDelete: _vm.handleDelete,
                        handleRedoDelete: function ($event) {
                          _vm.openBrand = true
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "specialDiyBtn",
                          fn: function () {
                            return [
                              _c(
                                "el-dropdown",
                                {
                                  staticClass: "startUsing",
                                  attrs: {
                                    "split-button": "",
                                    size: "mini",
                                    icon: "el-icon-circle-check",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleIsStop(false)
                                    },
                                    command: function ($event) {
                                      return _vm.handleIsStop(true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" 启用 "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "禁用" } },
                                        [_vm._v("禁用")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["system:employee:list:edit"],
                                      expression:
                                        "['system:employee:list:edit']",
                                    },
                                  ],
                                  staticClass: "startUsing",
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-edit",
                                    size: "mini",
                                    disabled: _vm.single,
                                  },
                                  on: { click: _vm.handleAuthRole },
                                },
                                [_vm._v("分配角色 ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTable,
                            expression: "loadingTable",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          data: _vm.employeeList,
                          border: "",
                          ":max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                        on: {
                          "selection-change": _vm.handleSelectionChange,
                          "row-click": _vm.handleRowClick,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "50",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          key: "employeeNo",
                          attrs: {
                            label: "员工编号",
                            align: "center",
                            prop: "employeeNo",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleUpdate(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.employeeNo) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          key: "employeeName",
                          attrs: {
                            label: "员工姓名",
                            align: "center",
                            prop: "employeeName",
                          },
                        }),
                        _c("el-table-column", {
                          key: "status",
                          attrs: { label: "员工状态", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 0
                                    ? _c("span", [_vm._v("正常")])
                                    : _vm._e(),
                                  scope.row.status == 1
                                    ? _c("span", [_vm._v("禁用")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          key: "deptName",
                          attrs: {
                            label: "所在部门",
                            align: "center",
                            prop: "dept.deptName",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          key: "telephone",
                          attrs: {
                            label: "手机号码",
                            align: "center",
                            prop: "telephone",
                            width: "120",
                          },
                        }),
                        _c("el-table-column", {
                          key: "userName",
                          attrs: {
                            label: "用户账户",
                            align: "center",
                            prop: "userName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "创建时间",
                            align: "center",
                            prop: "createTime",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseTime(scope.row.createTime)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("bottomPage", {
                      attrs: {
                        getList: _vm.getList,
                        idsCom: _vm.ids,
                        totalCom: _vm.total,
                      },
                      on: { selectAllCom: _vm.selectAll },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 640, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDialog,
                        expression: "loadingDialog",
                      },
                    ],
                    ref: "form",
                    attrs: {
                      model: _vm.formData,
                      inline: "",
                      rules: _vm.rules,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "员工编号", prop: "employeeNo" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: {
                            size: "mini",
                            disabled:
                              _vm.formData.isInit && _vm.dialogType === "edit",
                            placeholder: "请输入员工编号",
                            maxlength: "30",
                          },
                          model: {
                            value: _vm.formData.employeeNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "employeeNo", $$v)
                            },
                            expression: "formData.employeeNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "员工姓名", prop: "employeeName" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入员工姓名",
                            maxlength: "30",
                          },
                          model: {
                            value: _vm.formData.employeeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "employeeName", $$v)
                            },
                            expression: "formData.employeeName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号码", prop: "telephone" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入手机号码",
                            maxlength: "11",
                          },
                          model: {
                            value: _vm.formData.telephone,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "telephone", $$v)
                            },
                            expression: "formData.telephone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.dialogType === "add"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "x-f marL30",
                            attrs: {
                              label: " ",
                              "label-width": "2",
                              prop: "authCode",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: {
                                size: "mini",
                                placeholder: "请输入验证码",
                                oninput: "value=value.replace(/[^0-9]/g,'')",
                                maxlength: "11",
                              },
                              on: { input: _vm.findByVerifyCode },
                              model: {
                                value: _vm.formData.authCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "authCode", $$v)
                                },
                                expression: "formData.authCode",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "marL10",
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: _vm.isDisabled,
                                },
                                on: { click: _vm.getMessage },
                              },
                              [_vm._v(_vm._s(_vm.messageInfo) + " ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showPassword && _vm.dialogType === "add"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "密码", prop: "password" } },
                          [
                            _c("el-input", {
                              staticClass: "w200",
                              attrs: {
                                size: "mini",
                                "auto-complete": "new-password",
                                "show-password": "",
                                placeholder: "请输入密码",
                                maxlength: "18",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.formData.password =
                                    _vm.formData.password.trim()
                                },
                              },
                              model: {
                                value: _vm.formData.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "password", $$v)
                                },
                                expression: "formData.password",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showPassword && _vm.dialogType === "add"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "确认密码", prop: "newPassword" } },
                          [
                            _c("el-input", {
                              staticClass: "w200",
                              attrs: {
                                size: "mini",
                                "auto-complete": "new-password",
                                "show-password": "",
                                placeholder: "请输入确认密码",
                                maxlength: "18",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.formData.newPassword =
                                    _vm.formData.newPassword.trim()
                                },
                              },
                              model: {
                                value: _vm.formData.newPassword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "newPassword", $$v)
                                },
                                expression: "formData.newPassword",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属部门", prop: "deptId" } },
                      [
                        _c("treeselect", {
                          staticClass: "w200",
                          attrs: {
                            size: "mini",
                            options: _vm.deptOptions,
                            "show-count": true,
                            placeholder: "请选择归属部门",
                          },
                          model: {
                            value: _vm.formData.deptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "deptId", $$v)
                            },
                            expression: "formData.deptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "岗位", prop: "postIds" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w200",
                            attrs: {
                              size: "mini",
                              multiple: "",
                              placeholder: "请选择岗位",
                            },
                            model: {
                              value: _vm.formData.postIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "postIds", $$v)
                              },
                              expression: "formData.postIds",
                            },
                          },
                          _vm._l(_vm.postOptions, function (item) {
                            return _c("el-option", {
                              key: item.postId,
                              attrs: {
                                label: item.postName,
                                value: item.postId,
                                disabled: +item.status === 1,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色", prop: "roleIds" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w200",
                            attrs: {
                              size: "mini",
                              multiple: "",
                              placeholder: "请选择角色",
                            },
                            model: {
                              value: _vm.formData.roleIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "roleIds", $$v)
                              },
                              expression: "formData.roleIds",
                            },
                          },
                          _vm._l(_vm.roleOptions, function (item) {
                            return _c("el-option", {
                              key: item.roleId,
                              attrs: {
                                label: item.roleName,
                                value: item.roleId,
                                disabled: +item.status === 1,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "请输入备注",
                          },
                          model: {
                            value: _vm.formData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "remark", $$v)
                            },
                            expression: "formData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v("确 定 ")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                  [_vm._v("取 消")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("selectEmployee", {
        attrs: {
          OpenBrand: _vm.openBrand,
          confirmText: "还原",
          refreshSearch: _vm.refreshSearch,
        },
        on: {
          "update:OpenBrand": function ($event) {
            _vm.openBrand = $event
          },
          "update:open-brand": function ($event) {
            _vm.openBrand = $event
          },
          brandList: _vm.getBrandList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }