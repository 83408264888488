<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch">
            <div class="x-bc">
              <div>
                <el-form-item label="员工名称" prop="employeeName">
                  <el-input v-model="queryParams.employeeName" placeholder="请输入员工名称" clearable size="mini"
                    @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="手机号码" prop="telephone">
                  <el-input v-model="queryParams.telephone" placeholder="请输入手机号码" clearable size="mini"
                    @keyup.enter.native="handleQuery" />
                </el-form-item>
              </div>
              <div>
                <el-form-item>
                  <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>

    <cardTitleCom cardTitle="角色管理" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <operatingButton :isAddBtn="false" :isDeleteBtn="false" :multiple="multiple" :getList="getList">
            <template #specialDiyBtn>
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="openSelectUser">添加员工</el-button>
              <!-- v-hasPermi="['system:role:add']" -->
              <el-button type="danger" icon="el-icon-circle-close" size="mini" :disabled="multiple"
                @click="cancelAuthUserAll">批量取消授权</el-button>
              <!-- v-hasPermi="['system:role:remove']" -->
              <el-button type="warning" icon="el-icon-close" size="mini" @click="handleClose">关闭</el-button>
            </template>
          </operatingButton>
          <el-table v-loading="loading" :data="employeeList" @selection-change="handleSelectionChange" border
            @row-click="handleRowClick" ref="multipleTable" :height="tableHeight" :max-height="tableHeight">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="员工名称" prop="employeeName" :show-overflow-tooltip="true" />
            <el-table-column label="员工编号" prop="employeeNo" :show-overflow-tooltip="true" />
            <el-table-column label="手机" prop="telephone" :show-overflow-tooltip="true" />
            <el-table-column label="创建时间" align="center" prop="createTime" width="180">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-circle-close"
                  @click="cancelAuthUser(scope.row)">取消授权</el-button>
              </template>
            </el-table-column>
          </el-table>
          <bottomPage v-model="queryParams" :getList="getList" :idsCom="employeeIds" :totalCom="total"
            @selectAllCom="selectAll" />
        </div>
      </template>
    </cardTitleCom>

    <!-- <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar> -->

    <!-- <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->
    <select-user ref="select" :roleId="queryParams.roleId" @ok="handleQuery" />
  </div>
</template>

<script>
import {
  allocatedUserList,
  authUserCancel,
  authUserCancelAll
} from '@/api/system/role'
import selectUser from './selectUser'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import { mapMutations } from 'vuex'
export default {
  name: 'AuthUser',
  dicts: ['sys_normal_disable'],
  components: {
    selectUser,
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage,
    CDialog
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中用户组
      employeeIds: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 员工表格数据
      employeeList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        roleId: undefined,
        employeeName: undefined,
        telephone: undefined
      },

      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  created() {
    const roleId = this.$route.params && this.$route.params.roleId
    if (roleId) {
      this.queryParams.roleId = roleId
      this.getList()
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 128
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight

      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations('tagsView', ['DEL_VISITED_VIEW']),

    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loading = true
        const res = await allocatedUserList({
          ...this.queryParams,

          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.employeeList = res.rows
        //表格多选
        this.employeeList.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loading = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    /** 查询授权用户列表 */
    getList() {
      this.loading = true
      allocatedUserList(this.queryParams).then(response => {
        this.employeeList = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 返回按钮
    handleClose() {
      // const obj = { path: '/system/role' }
      // this.$tab.closeOpenPage(obj)
      this.$router.go(-1)
      const find = this.$store.getters.visitedViews.find(
        item => item.path === this.$route.path
      )
      this.DEL_VISITED_VIEW(find)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.employeeIds = selection.map(item => item.employeeId)
      this.multiple = !selection.length
    },
    /** 打开授权用户表弹窗 */
    openSelectUser() {
      this.$refs.select.show()
    },
    /** 取消授权按钮操作 */
    cancelAuthUser(row) {
      const roleId = this.queryParams.roleId
      this.$modal
        .confirm('确认要取消该用户角色吗？')
        .then(function () {
          return authUserCancel({ employeeId: row.employeeId, roleId: roleId })
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('取消授权成功')
        })
        .catch(() => { })
    },
    /** 批量取消授权按钮操作 */
    cancelAuthUserAll(row) {
      const roleId = this.queryParams.roleId
      const employeeIds = this.employeeIds
      this.$modal
        .confirm('是否取消选中用户授权数据项？')
        .then(function () {
          return authUserCancelAll({ roleId: roleId, employeeIds: employeeIds })
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('取消授权成功')
        })
        .catch(() => { })
    }
  }
}
</script>
<style lang="scss" scoped>
// 搜索
.navSearch {
  padding: 10px 10px 10px 20px;
  //筛选条件
  .searchCriteria {
    padding-top: 10px;
    .selectFrameUp {
      display: inline-block;
      margin-right: 15px;
    }
  }
}
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
.brandInfo {
  height: calc(100% - 104px);
  //源单信息表格
  .tableContent {
    margin: 6px 10px 10px 10px;
  }
}
</style>
