var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "客供分组",
          isEditBtn: true,
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.groupTreeOptions,
        },
        on: {
          treeNodeId: _vm.getTreeNodeId,
          appendTree: _vm.appendTree,
          editTree: _vm.editTree,
          removeTree: _vm.removeTree,
        },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isAdvancedSearch: false,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入组别编码、名称",
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "单据信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c("operatingButton", {
                      attrs: {
                        getList: _vm.getList,
                        isRecycleBtn: true,
                        isDeleteBtn: false,
                        multiple: _vm.multiple,
                      },
                      on: {
                        handleAdd: _vm.handleAdd,
                        handleDelete: _vm.handleDelete,
                        handleRedoDelete: function ($event) {
                          _vm.dialogOptions.show = true
                        },
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              "tooltip-effect": "dark",
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "selection", width: "55" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "序号",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "groupNo",
                                label: "组别编码",
                                "show-overflow-tooltip": "",
                                width: "120",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.groupNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "groupName",
                                label: "组别名称",
                                "show-overflow-tooltip": "",
                                width: "120",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "zjm",
                                label: "助记码",
                                "show-overflow-tooltip": "",
                                width: "120",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "remark",
                                label: "备注",
                                "show-overflow-tooltip": "",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "updateBy",
                                label: "修改人",
                                "show-overflow-tooltip": "",
                                width: "120",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "updateTime",
                                label: "修改时间",
                                "show-overflow-tooltip": "",
                                width: "155",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "createBy",
                                label: "创建人",
                                "show-overflow-tooltip": "",
                                width: "120",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "createTime",
                                label: "创建时间",
                                "show-overflow-tooltip": "",
                                width: "155",
                                align: "center",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            idsCom: _vm.ids,
                            totalCom: _vm.total,
                          },
                          on: { selectAllCom: _vm.selectAll },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 500, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDia,
                        expression: "loadingDia",
                      },
                    ],
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "上级分组", prop: "parentId" } },
                      [
                        _c("treeselect", {
                          attrs: {
                            options: _vm.groupTreeOptions,
                            "show-count": true,
                            placeholder: "请选择上级分组",
                            normalizer: _vm.normalizer,
                          },
                          on: { input: _vm.inputSelect },
                          model: {
                            value: _vm.form.parentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "parentId", $$v)
                            },
                            expression: "form.parentId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "组别编码", prop: "groupNo" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入组别编码" },
                          model: {
                            value: _vm.form.groupNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "groupNo", $$v)
                            },
                            expression: "form.groupNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "组别名称", prop: "groupName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入组别名称" },
                          model: {
                            value: _vm.form.groupName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "groupName", $$v)
                            },
                            expression: "form.groupName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 30 字符之间",
                            maxlength: "30",
                            autosize: { minRows: 2, maxRows: 4 },
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }