<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form
            :model="queryParams"
            ref="queryForm"
            v-show="showSearch"
            label-width="90px"
          >
            <div class="x-bc">
              <div class="x-f">
                <el-form-item label="部门名称" prop="deptName">
                  <el-input
                    v-model="queryParams.deptName"
                    placeholder="请输入部门名称"
                    clearable
                    @keyup.enter.native="handleQuery"
                    size="mini"
                  />
                </el-form-item>

                <el-form-item label="状态" prop="status" class="marL10">
                  <el-select
                    v-model="queryParams.status"
                    placeholder="请选择状态"
                    clearable
                    size="mini"
                  >
                    <el-option label="正常" value="0"></el-option>
                    <el-option label="停用" value="1"></el-option>
                    <el-option label="删除" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleQuery"
                    >查询</el-button
                  >
                  <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="部门管理" class="deptInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :isAddBtn="false"
            :isRecycleBtn="true"
            :isDeleteBtn="false"
            :isExportBtn="false"
            :isRefreshBtn="false"
            :isExpandAllBtn="true"
            :multiple="multiple"
            @handleDelete="handleDelete"
            @toggleExpandAll="toggleExpandAll"
            @handleRedoDelete="openDept = true"
          >
            <template #specialDiyBtn>
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                >新增</el-button
              >

              <!-- <el-button
                type="info"
                plain
                icon="el-icon-sort"
                size="mini"
                @click="toggleExpandAll"
                >展开/折叠</el-button
              > -->
            </template>
          </operatingButton>
          <el-table
            @selection-change="handleSelectionChange"
            v-if="refreshTable"
            v-loading="loading"
            :data="deptList"
            :max-height="tableHeight"
            :height="tableHeight"
            row-key="deptId"
            :default-expand-all="isExpandAll"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            border
          >
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="部门名称"
              width="260"
            ></el-table-column>
            <el-table-column
              prop="deptNo"
              label="部门编号"
              width="260"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="orderNum"
              label="排序"
              width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.sys_normal_disable"
                  :value="scope.row.delFlag"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              width="200"
            >
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
                  >修改</el-button
                >
                <!-- v-hasPermi="['system:dept:edit']" -->
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-plus"
                  @click="handleAdd(scope.row)"
                  >新增</el-button
                >
                <!-- v-hasPermi="['system:dept:add']" -->
                <!--   <el-button
                  v-if="scope.row.parentId != 0"
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  :disabled="scope.row.delFlag === '1'"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >

                <el-button
                  v-if="scope.row.parentId != 0"
                  size="mini"
                  type="text"
                  icon="el-icon-refresh"
                  :disabled="scope.row.delFlag === '0'"
                  @click="handleComeBack(scope.row)"
                  >还原</el-button
                > -->
                <!-- v-hasPermi="['system:dept:remove']" -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>

    <!-- v-hasPermi="['system:dept:add']" -->

    <!-- <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar>
   -->

    <c-dialog :title="title" :width="600" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-row>
            <el-col :span="24" v-if="form.parentId !== 0">
              <el-form-item label="上级部门" prop="parentId">
                <treeselect
                  v-model="form.parentId"
                  :options="deptOptions"
                  :normalizer="normalizer"
                  placeholder="选择上级部门"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="部门编号" prop="deptNo">
                <el-input
                  :disabled="form.isInit && dialogType == 'edit'"
                  v-model="form.deptNo"
                  placeholder="请输入部门编号"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="部门名称" prop="deptName">
                <el-input
                  v-model="form.deptName"
                  placeholder="请输入部门名称"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="负责人" prop="leader">
                <el-input
                  v-model="form.leader"
                  placeholder="请输入负责人"
                  maxlength="20"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="phone">
                <el-input
                  v-model="form.phone"
                  placeholder="请输入联系电话"
                  maxlength="11"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="邮箱" prop="email">
                <el-input
                  v-model="form.email"
                  placeholder="请输入邮箱"
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="显示排序" prop="orderNum">
                <el-input-number
                  v-model="form.orderNum"
                  controls-position="right"
                  :min="0"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="部门状态">
                <el-radio-group v-model="form.status">
                  <el-radio
                    v-for="dict in dict.type.sys_normal_disable"
                    :key="dict.value"
                    :label="dict.value"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <selectDeptment
      :OpenBrand.sync="openDept"
      confirmText="还原"
      :refreshSearch="refreshSearch"
      @brandList="getBrandList"
    />
  </div>
</template>

<script>
import {
  listDept,
  getDept,
  delDept,
  addDept,
  updateDept,
  listDeptExcludeChild,
  revertDeptAPI
} from '@/api/system/dept'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import selectDeptment from '@/views/components/selectDeptment' //部门管理回收站弹窗
export default {
  name: 'Dept',
  dicts: ['sys_normal_disable'],
  components: {
    Treeselect,
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage,
    CDialog,
    selectDeptment
  },
  data () {
    return {
      openDept: false, //回收站弹窗
      // 选中数组 ID
      ids: [],
      // 选中数组
      selectData: [],
      // 非多个禁用
      multiple: true,
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 表格树数据
      deptList: [],
      // 部门树选项
      deptOptions: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否展开，默认全部展开
      isExpandAll: true,
      // 重新渲染表格状态
      refreshTable: true,
      // 查询参数
      queryParams: {
        deptName: undefined,
        status: undefined
      },
      // 表单参数
      form: {},
      // 标记是否编辑
      dialogType: '',
      // 表单校验
      rules: {
        parentId: [
          { required: true, message: '上级部门不能为空', trigger: 'blur' }
        ],
        deptName: [
          { required: true, message: '部门名称不能为空', trigger: 'blur' }
        ],
        deptNo: [
          { required: true, message: '部门编号不能为空', trigger: 'blur' }
        ],
        orderNum: [
          { required: true, message: '显示排序不能为空', trigger: 'blur' }
        ],
        email: [
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        phone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ]
      },
      //回收站
      refreshSearch: {
        pageNum: 1,
        pageSize: 15,
        delFlag: '1'
      },
      vivwH: 0, //页面高度
      searchH: 0 //搜索框高度
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    this.getList()
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    },
    /** 查询部门列表 */
    getList () {
      this.loading = true
      listDept(this.queryParams).then(response => {
        this.deptList = this.handleTree(response.data, 'deptId')
        this.loading = false
      })
    },
    /** 转换部门数据结构 */
    normalizer (node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children
      }
    },

    /** 删除按钮操作 */
    handleDelete () {
      if (this.selectData.some(row => row.isInit)) {
        this.$message.warning('当前选部门中包含不可删除的部门')
        return
      }

      this.$modal
        .confirm('是否确认删除选中的数据项？')
        .then(() => {
          return delDept(this.ids)
        })
        .then(() => {
          this.$modal.msgSuccess('删除成功')
          this.getList() // 删除成功后刷新数据
        })
        .catch(() => {
          console.error('删除失败')
        })
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.deptId)
      console.log('选中的', this.ids)
      this.selectData = selection
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    //选择回收部门
    async getBrandList (val) {
      let deptIdArr = val.map(v => v.deptId)
      await revertDeptAPI(deptIdArr)
      this.getList()
      this.$message({
        message: '还原成功',
        type: 'success'
      })
    },
    //  handleRowClick (row) {
    //   // 获取表格对象
    //   // 切换行选中状态
    //   this.$refs.multipleTable.toggleRowSelection(row)
    // },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        deptId: undefined,
        parentId: undefined,
        deptName: undefined,
        orderNum: undefined,
        leader: undefined,
        phone: undefined,
        email: undefined,
        status: '0'
      }
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.resetForm('queryForm')
      this.handleQuery()
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.dialogType = 'add'
      this.reset()
      if (row != undefined) {
        this.form.parentId = row.deptId
      }
      this.open = true
      this.title = '添加部门'
      listDept().then(response => {
        this.deptOptions = this.handleTree(response.data, 'deptId')
      })
    },
    /** 展开/折叠操作 */
    toggleExpandAll () {
      this.refreshTable = false
      this.isExpandAll = !this.isExpandAll
      this.$nextTick(() => {
        this.refreshTable = true
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row) {
      this.dialogType = 'edit'
      console.log('修改部门 = ', row)
      this.reset()
      getDept(row.deptId).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改部门'
      })
      listDeptExcludeChild(row.deptId).then(response => {
        this.deptOptions = this.handleTree(response.data, 'deptId')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.deptId != undefined) {
            updateDept(this.form).then(response => {
              this.$modal.msgSuccess('修改成功')
              this.open = false
              this.getList()
            })
          } else {
            addDept(this.form).then(response => {
              this.$modal.msgSuccess('新增成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },

    /** 还原按钮操作 */
    handleComeBack (row) {
      this.$modal
        .confirm('是否确认还原名称为"' + row.deptName + '"的数据项？')
        .then(function () {
          return revertDeptAPI([row.deptId])
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    }

    // //还原按钮
    // handleComeBack(row) {
    //   console.log('this.deptList',this.deptList);
    //   const selectedData = this.deptList.filter((item) =>
    //     this.ids.includes(item.deptId)
    //   );

    //   // 检查是否有未删除状态的数据
    //   const hasUndeletedData = selectedData.some(
    //     (item) => item.delFlag === "0"
    //   );

    //   if (hasUndeletedData) {
    //     // 提示未删除状态，不需要还原
    //     this.$modal.msgWarning("选中的数据包含未删除状态，不需要还原");
    //     return;
    //   }

    //   const deptIds = this.ids;
    //   this.$modal
    //     .confirm(
    //       '是否确认还原用户编号为"' + deptIds.join(", ") + '"的数据项？'
    //     )
    //     .then(() => {
    //       return revertAPI(deptIds);
    //     })
    //     .then(() => {
    //       this.getList();
    //       this.$modal.msgSuccess("还原成功");
    //     })
    //     .catch(() => {});
    // },
  }
}
</script>
<style lang="scss" scoped>
//整体树
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);

  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }
  .deptInfo {
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
</style>
