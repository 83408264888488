var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "bigForm",
          attrs: {
            model: _vm.bigForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "topOperatingButton",
            {
              attrs: {
                isSubmitAddBtn: false,
                isAuditBillBtn: false,
                id: "topOperatingButton",
              },
              on: { submitForm: _vm.submitForm, getQuit: _vm.getQuit },
            },
            [
              _c(
                "template",
                { slot: "specialDiyBtn" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              staticStyle: { "margin-bottom": "0px" },
              attrs: { hideCard: true },
            },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c("div", { staticClass: "navTitle" }, [_vm._v("基本信息")]),
                  _c(
                    "el-row",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户编码", prop: "partnerNo" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "20" },
                                model: {
                                  value: _vm.bigForm.partnerNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "partnerNo", $$v)
                                  },
                                  expression: "bigForm.partnerNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "客户名称", prop: "partnerName" },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "40" },
                                model: {
                                  value: _vm.bigForm.partnerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "partnerName", $$v)
                                  },
                                  expression: "bigForm.partnerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户简称",
                                prop: "partnerSimpleName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "40" },
                                model: {
                                  value: _vm.bigForm.partnerSimpleName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.bigForm,
                                      "partnerSimpleName",
                                      $$v
                                    )
                                  },
                                  expression: "bigForm.partnerSimpleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务员", prop: "region" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择业务员",
                                  },
                                  model: {
                                    value: _vm.bigForm.saleEmployeeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.bigForm,
                                        "saleEmployeeId",
                                        $$v
                                      )
                                    },
                                    expression: "bigForm.saleEmployeeId",
                                  },
                                },
                                _vm._l(_vm.dataList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.employeeName,
                                      value: item.employeeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户区域", prop: "areaId" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  size: "mini",
                                  options: _vm.treeArea,
                                  "show-count": true,
                                  placeholder: "请选择客户区域",
                                  normalizer: _vm.normalizer,
                                },
                                on: { input: _vm.inputSelectArea },
                                model: {
                                  value: _vm.bigForm.areaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "areaId", $$v)
                                  },
                                  expression: "bigForm.areaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户组别", prop: "groupId" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  size: "mini",
                                  options: _vm.treeGroup,
                                  "show-count": true,
                                  placeholder: "请选择客户组别",
                                  normalizer: _vm.normalizer,
                                },
                                on: { input: _vm.inputSelectGroup },
                                model: {
                                  value: _vm.bigForm.groupId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "groupId", $$v)
                                  },
                                  expression: "bigForm.groupId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "价格方案" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择价格方案",
                                  },
                                  model: {
                                    value: _vm.bigForm.priceCaseId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bigForm, "priceCaseId", $$v)
                                    },
                                    expression: "bigForm.priceCaseId",
                                  },
                                },
                                _vm._l(_vm.priceCaseList, function (item) {
                                  return _c("el-option", {
                                    key: item.priceCaseId,
                                    attrs: {
                                      label: item.priceCaseName,
                                      value: item.priceCaseId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "结算方式" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.bigForm.payType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bigForm, "payType", $$v)
                                    },
                                    expression: "bigForm.payType",
                                  },
                                },
                                _vm._l(_vm.dict.type.pay_type, function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: { label: parseInt(dict.value) },
                                    },
                                    [_vm._v(_vm._s(dict.label))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户类型" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.bigForm.partnerType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bigForm, "partnerType", $$v)
                                    },
                                    expression: "bigForm.partnerType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("客供"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("客户"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.bigForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bigForm, "status", $$v)
                                    },
                                    expression: "bigForm.status",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.common_status,
                                  function (dict) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: dict.value,
                                        attrs: { label: parseInt(dict.value) },
                                      },
                                      [_vm._v(_vm._s(dict.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "navTitle" }, [_vm._v("账款信息")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "授信额度(元)",
                                prop: "creditAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  disabled: _vm.disabled,
                                  maxlength: "20",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.limitInputlength(
                                      _vm.bigForm.creditAmount,
                                      "bigForm.creditAmount"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.bigForm.creditAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "creditAmount", $$v)
                                  },
                                  expression: "bigForm.creditAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "已用额度(元)",
                                prop: "usedAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  disabled: _vm.disabled,
                                  maxlength: "20",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.limitInputlength(
                                      _vm.bigForm.usedAmount,
                                      "bigForm.usedAmount"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.bigForm.usedAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "usedAmount", $$v)
                                  },
                                  expression: "bigForm.usedAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预存余额(元)", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  disabled: _vm.disabled,
                                  maxlength: "20",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.limitInputlength(
                                      _vm.bigForm.prestoreAmount,
                                      "bigForm.prestoreAmount"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.bigForm.prestoreAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "prestoreAmount", $$v)
                                  },
                                  expression: "bigForm.prestoreAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "应收欠款(元)", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  disabled: _vm.disabled,
                                  maxlength: "20",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.limitInputlength(
                                      _vm.bigForm.name,
                                      "bigForm.name"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.bigForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "name", $$v)
                                  },
                                  expression: "bigForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "剩余额度(元)",
                                prop: "usableAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  disabled: true,
                                  maxlength: "20",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.limitInputlength(
                                      _vm.bigForm.usableAmount,
                                      "bigForm.usableAmount"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.usableAmount,
                                  callback: function ($$v) {
                                    _vm.usableAmount = $$v
                                  },
                                  expression: "usableAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "navTitle" }, [_vm._v("更多信息")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开户银行",
                                prop: "invoiceDepositBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "40" },
                                model: {
                                  value: _vm.bigForm.invoiceDepositBank,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.bigForm,
                                      "invoiceDepositBank",
                                      $$v
                                    )
                                  },
                                  expression: "bigForm.invoiceDepositBank",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开户名称",
                                prop: "invoiceBankName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "40" },
                                model: {
                                  value: _vm.bigForm.invoiceBankName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.bigForm,
                                      "invoiceBankName",
                                      $$v
                                    )
                                  },
                                  expression: "bigForm.invoiceBankName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纳税人识别号",
                                prop: "invoiceLicenseNo",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "40" },
                                model: {
                                  value: _vm.bigForm.invoiceLicenseNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.bigForm,
                                      "invoiceLicenseNo",
                                      $$v
                                    )
                                  },
                                  expression: "bigForm.invoiceLicenseNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "邮编", prop: "postalCode" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "20" },
                                model: {
                                  value: _vm.bigForm.postalCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "postalCode", $$v)
                                  },
                                  expression: "bigForm.postalCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开户地址", prop: "regionId" } },
                            [
                              _c("el-cascader", {
                                attrs: {
                                  size: "mini",
                                  options: _vm.provinceList,
                                  props: _vm.defaultProp,
                                  placeholder: "请选择开户地址",
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.bigForm.regionId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "regionId", $$v)
                                  },
                                  expression: "bigForm.regionId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "详细地址", prop: "address" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini", maxlength: "80" },
                                model: {
                                  value: _vm.bigForm.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bigForm, "address", $$v)
                                  },
                                  expression: "bigForm.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "navTitle" }, [_vm._v("联系方式")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "linkmanList", "label-width": "0" } },
                    [
                      _c(
                        "div",
                        { staticClass: "padR10 padL10" },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "dragTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.bigForm.linkmanList,
                                "row-key": "columnId",
                                height: "500",
                                "max-height": "500",
                                border: "",
                              },
                              on: {
                                "cell-mouse-enter": _vm.cellMouseEnter,
                                "cell-mouse-leave": _vm.cellMouseLeave,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: _vm.tableCellLabel,
                                  width: "80",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.hoverRow
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-circle-plus operatePush",
                                              attrs: { disabled: _vm.disabled },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.row(
                                                    "push",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        scope.row.hoverRow
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-remove operateDel",
                                              attrs: { disabled: _vm.disabled },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.row(
                                                    "del",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        !scope.row.hoverRow
                                          ? _c("div", [
                                              _vm._v(_vm._s(scope.$index + 1)),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "联系人名称",
                                  width: "180",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "contactName",
                                          attrs: {
                                            size: "mini",
                                            maxlength: "40",
                                          },
                                          model: {
                                            value: scope.row.linkMan,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "linkMan",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.linkMan",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "联系人电话",
                                  width: "230",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "contactNo",
                                          attrs: {
                                            size: "mini",
                                            maxlength: "20",
                                          },
                                          model: {
                                            value: scope.row.linkManTel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "linkManTel",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.linkManTel",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "联系人地址", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "contactAddress",
                                          attrs: {
                                            size: "mini",
                                            maxlength: "80",
                                          },
                                          model: {
                                            value: scope.row.linkAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "linkAddress",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.linkAddress",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "备注", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "contactAddress",
                                          attrs: {
                                            size: "mini",
                                            maxlength: "80",
                                          },
                                          model: {
                                            value: scope.row.remark,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "remark", $$v)
                                            },
                                            expression: "scope.row.remark",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }