<template>
  <!-- 整体内容 -->
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard categoryTreeTitle="客供区域" @treeNodeId="getTreeNodeId" @appendTree="appendTree" @editTree="editTree"
      @removeTree="removeTree" :isEditBtn="true" :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight"
      :categoryTreeOptions="treeData" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList"
              :isAdvancedSearch="false" :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入区域编码、名称" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="单据信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
              @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
            <div>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange" border v-loading="loadingTable" @row-click="handleRowClick"
                :max-height="tableHeight" :height="tableHeight">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column align="center" type="index" label="序号" width="80">
                </el-table-column>
                <el-table-column prop="areaNo" label="区域编码" show-overflow-tooltip width="120" align="center">
                  <template slot-scope="scope">
                    <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                      {{ scope.row.areaNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="areaName" label="区域名称" show-overflow-tooltip width="120" align="center">
                </el-table-column>

                <el-table-column prop="zjm" label="助记码" show-overflow-tooltip width="120" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" show-overflow-tooltip align="center">
                </el-table-column>
                <el-table-column prop="updateBy" label="修改人" show-overflow-tooltip width="120" align="center">
                </el-table-column>
                <el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip width="155" align="center">
                </el-table-column>
                <el-table-column prop="createBy" label="创建人" show-overflow-tooltip width="120" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip width="155" align="center">
                </el-table-column>
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 添加修改区域对话框 -->
    <c-dialog :title="title" :width="500" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="上级区域" prop="parentId">
                <treeselect v-model="form.parentId" :options="treeData" :show-count="true" placeholder="请选择上级区域"
                  :normalizer="normalizer" @input="inputSelect" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="区域编码" prop="areaNo">
                <el-input v-model="form.areaNo" placeholder="请输入区域编码" maxlength="11" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="区域名称" prop="areaName">
                <el-input v-model="form.areaName" placeholder="请输入区域名称" maxlength="30" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="20">
              <el-form-item label="备注">
                <el-input v-model="form.remark" type="textarea" placeholder="备注长度介于 1 和 30 字符之间" maxlength="30"
                  :autosize="{ minRows: 2, maxRows: 4 }" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="getRender" />
  </div>
</template>

<script>
import {
  getAreaTree,
  listArea,
  addArea,
  delArea,
  getAreaDetail,
  updateArea,
  restorePartnerArea
} from '@/api/goods/area.js'
import { getToken } from '@/utils/auth'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'partnerArea',
  components: {
    Treeselect,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    CDialog,
    Dialog2
  },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listArea,
          restoreListApi: restorePartnerArea,
          id: 'areaId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'areaNos', label: '区域编码' },
                { filter: 'areaNames', label: '区域名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'areaNo',
              label: '区域编码',
              minWidth: 180
            },
            {
              prop: 'areaName',
              label: '区域名称',
              minWidth: 180
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 100
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      //tree选中的id
      selectTreeId: undefined,
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'areaNos', name: '区域编码' },
        { parameter: 'areaNames', name: '区域名称' }
      ],
      delFlag: null, //删除标志
      loading: false, //全局加载
      loadingTable: false, //表格加载
      isExpand: true, //是否默认展开
      single: true, //删除按钮禁用状态
      multiple: true, //编辑按钮禁用状态
      total: 0, //分页总条数
      title: null, //对话框标题
      open: false, //对话框显示隐藏
      name: [], //选中数组的名称
      ids: [], // 选中数组
      //   左边树装
      treeData: [],
      //   右边表格
      tableData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      form: {
        areaName: null,
        areaNo: null,
        parentId: null,
        remark: null
      },
      formLabelWidth: '120px', //对话框目录

      //表格选中内容
      multipleSelection: [],
      // 分类导入参数
      upload: {
        // 是否显示弹出层（分类导入）
        open: false,
        // 弹出层标题（分类导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的分类数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + '/system/user/importData'
      },

      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        areaName: null,
        areaNo: null,
        parentId: null,
        zjm: null
      },
      // 表单校验
      rules: {
        parentId: [
          {
            required: true,
            message: '上级区域不能为空',
            trigger: 'input'
          }
        ],
        areaNo: [
          {
            required: true,
            message: '区域编码不能为空',
            trigger: ['blur', 'change']
          },
          { pattern: /^[0-9]{1,20}$/, message: '请输入1 ~ 20个数值类型的字符' }
        ],
        areaName: [
          {
            required: true,
            message: '区域名称不能为空',
            trigger: ['blur', 'change']
          },
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },

  watch: {
    open(val) {
      if (!val) {
        this.$refs.form.resetFields()
      }
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight() {
      return this.vivwH - 10
    },
    boxInternalHeight() {
      return this.vivwH - 83
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.getRender()
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击类别树
    getTreeNodeId(value) {
      this.selectTreeId = value
      this.queryParams.parentId = value
      this.getList()
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listArea({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //  修改\点击编号
    async handleDetail(row) {
      this.reset()
      getAreaDetail(row.areaId).then(response => {
        this.form = response.data
        this.open = true
        this.title = '编辑客供区域'
      })
    },
    // 下拉框校验方法
    inputSelect() {
      this.$refs.form.validateField('parentId')
    },

    //渲染事件
    getRender() {
      this.loading = true
      this.getList()
      this.getAreaTree()
      this.loading = false
    },
    async getAreaTree() {
      const res = await getAreaTree() //获取树数据
      this.treeData = res.data //赋值给tree
    },

    /** 查询分页列表 */
    getList() {
      this.loadingTable = true
      listArea(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loadingTable = false
      })
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },

    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        areaName: null,
        areaNo: null,
        parentId: null,
        remark: null
      }
      this.resetForm('form')
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      const delFlagStatus = selection.some(v => v.delFlag == '1')
      this.delFlag = delFlagStatus //删除标志
      this.ids = selection.map(item => item.areaId)
      this.name = selection.map(item => item.areaName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.open = true
      this.resetForm('form')
      this.form = {
        areaName: null,
        parentId: this.selectTreeId,
        areaNo: null,
        remark: null
      }
      this.title = '新增客供区域'
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.areaId != undefined) {
            updateArea(this.form).then(response => {
              this.$modal.msgSuccess('修改客供区域成功')
              this.open = false
              this.getRender()
            })
          } else {
            addArea(this.form).then(response => {
              this.$modal.msgSuccess('新增客供区域成功')
              this.open = false
              this.getRender()
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete() {
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${this.ids.length} </span>个客供区域吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(() => {
          return delArea(this.ids)
        })
        .then(() => {
          this.getRender()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => { })
    },
    //新增Tree用户组
    appendTree(data) {
      this.form = {
        areaName: null,
        parentId: data.id,
        areaNo: null,
        remark: null
      }
      this.resetForm('form')
      this.title = '新增客供区域'
      this.open = true
    },
    //编辑Tree用户组
    editTree(data) {
      this.title = '编辑客供区域'
      this.reset()
      this.getAreaTree()
      const areaId = data.id
      getAreaDetail(areaId).then(response => {
        this.form = response.data
        this.open = true
      })
    },

    //删除Tree用户组
    removeTree({ node, data }) {
      const res = this.tableData.every(v => v.delFlag == 1)
      if (res) {
        this.$message({
          message: '此项已经删除被品牌哦~',
          type: 'warning'
        })
        return
      }

      this.$confirm(`确定删除(${data.label})客供区域,是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        if (node.data.children) {
          this.$message({
            howClose: true,
            message: '请先删除子级客供区域',
            type: 'warning'
          })
          return
        }
        const Ids = [data.id]
        delArea(Ids).then(response => {
          this.$modal.msgSuccess('删除成功')
          this.getRender()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
        //筛选条件
        .searchCriteria {
          padding-top: 10px;
          .selectFrameUp {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}
</style>
