var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showSearch,
                        expression: "showSearch",
                      },
                    ],
                    ref: "queryForm",
                    attrs: {
                      model: _vm.queryParams,
                      size: "small",
                      inline: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "x-bc" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "角色名称", prop: "roleName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入角色名称",
                                  clearable: "",
                                  size: "mini",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleQuery($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryParams.roleName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "roleName", $$v)
                                  },
                                  expression: "queryParams.roleName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "marL10",
                              attrs: { label: "状态", prop: "status" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "角色状态",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.queryParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "status", $$v)
                                    },
                                    expression: "queryParams.status",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.sys_normal_disable,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: dict.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                    size: "mini",
                                  },
                                  on: { click: _vm.handleQuery },
                                },
                                [_vm._v("搜索")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: { click: _vm.resetQuery },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { staticClass: "brandInfo", attrs: { cardTitle: "角色管理" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("operatingButton", {
                  attrs: {
                    getList: _vm.getList,
                    isAddBtn: true,
                    isRecycleBtn: true,
                    isDeleteBtn: false,
                    isExportBtn: true,
                    isImportBtn: false,
                    isRefreshBtn: true,
                    multiple: _vm.multiple,
                  },
                  on: {
                    handleAdd: _vm.handleAdd,
                    handleDelete: _vm.handleDelete,
                    handleRedoDelete: function ($event) {
                      _vm.dialogOptions.show = true
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "specialDiyBtn",
                      fn: function () {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                icon: "el-icon-edit",
                                size: "mini",
                                disabled: _vm.single,
                              },
                              on: { click: _vm.handleUpdate },
                            },
                            [_vm._v("修改")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    attrs: {
                      data: _vm.roleList,
                      border: "",
                      height: _vm.tableHeight,
                      "max-height": _vm.tableHeight,
                    },
                    on: {
                      "selection-change": _vm.handleSelectionChange,
                      "row-click": _vm.handleRowClick,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "角色名称",
                        prop: "roleName",
                        "show-overflow-tooltip": true,
                        width: "150",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", target: "_blank" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(scope.row.roleName) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "显示顺序",
                        prop: "roleSort",
                        width: "100",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "状态", align: "center", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": "0",
                                  "inactive-value": "1",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleStatusChange(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.status,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "status", $$v)
                                  },
                                  expression: "scope.row.status",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        align: "center",
                        prop: "createTime",
                        width: "180",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.parseTime(scope.row.createTime))
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        "class-name": "small-padding fixed-width",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return row.roleId !== 1
                                ? [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCommand(
                                              "handleDataScope",
                                              row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("数据权限")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCommand(
                                              "handleAuthUser",
                                              row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("分配员工角色")]
                                    ),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    idsCom: _vm.ids,
                    totalCom: _vm.total,
                  },
                  on: { selectAllCom: _vm.selectAll },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 600, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色名称", prop: "roleName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入角色名称" },
                          model: {
                            value: _vm.form.roleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "roleName", $$v)
                            },
                            expression: "form.roleName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色顺序", prop: "roleSort" } },
                      [
                        _c("el-input-number", {
                          attrs: { "controls-position": "right", min: 0 },
                          model: {
                            value: _vm.form.roleSort,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "roleSort", $$v)
                            },
                            expression: "form.roleSort",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.sys_normal_disable,
                            function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: dict.value },
                                },
                                [_vm._v(_vm._s(dict.label))]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "菜单权限" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeExpand(
                                  $event,
                                  "menu"
                                )
                              },
                            },
                            model: {
                              value: _vm.menuExpand,
                              callback: function ($$v) {
                                _vm.menuExpand = $$v
                              },
                              expression: "menuExpand",
                            },
                          },
                          [_vm._v("展开/折叠")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeNodeAll(
                                  $event,
                                  "menu"
                                )
                              },
                            },
                            model: {
                              value: _vm.menuNodeAll,
                              callback: function ($$v) {
                                _vm.menuNodeAll = $$v
                              },
                              expression: "menuNodeAll",
                            },
                          },
                          [_vm._v("全选/全不选")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeConnect(
                                  $event,
                                  "menu"
                                )
                              },
                            },
                            model: {
                              value: _vm.form.menuCheckStrictly,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuCheckStrictly", $$v)
                              },
                              expression: "form.menuCheckStrictly",
                            },
                          },
                          [_vm._v("父子联动")]
                        ),
                        _c("el-tree", {
                          ref: "menu",
                          staticClass: "tree-border",
                          attrs: {
                            data: _vm.menuOptions,
                            "show-checkbox": "",
                            "node-key": "id",
                            "check-strictly": !_vm.form.menuCheckStrictly,
                            "empty-text": "加载中，请稍候",
                            props: _vm.defaultProps,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入内容",
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 600, showDialog: _vm.openDataScope },
        on: {
          "update:showDialog": function ($event) {
            _vm.openDataScope = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openDataScope = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  { attrs: { model: _vm.form, "label-width": "80px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色名称" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.form.roleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "roleName", $$v)
                            },
                            expression: "form.roleName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "权限范围" } },
                      [
                        _c(
                          "el-select",
                          {
                            on: { change: _vm.dataScopeSelectChange },
                            model: {
                              value: _vm.form.dataScope,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataScope", $$v)
                              },
                              expression: "form.dataScope",
                            },
                          },
                          _vm._l(_vm.dataScopeOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.dataScope == 2,
                            expression: "form.dataScope == 2",
                          },
                        ],
                        attrs: { label: "数据权限" },
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeExpand(
                                  $event,
                                  "dept"
                                )
                              },
                            },
                            model: {
                              value: _vm.deptExpand,
                              callback: function ($$v) {
                                _vm.deptExpand = $$v
                              },
                              expression: "deptExpand",
                            },
                          },
                          [_vm._v("展开/折叠")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeNodeAll(
                                  $event,
                                  "dept"
                                )
                              },
                            },
                            model: {
                              value: _vm.deptNodeAll,
                              callback: function ($$v) {
                                _vm.deptNodeAll = $$v
                              },
                              expression: "deptNodeAll",
                            },
                          },
                          [_vm._v("全选/全不选")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeConnect(
                                  $event,
                                  "dept"
                                )
                              },
                            },
                            model: {
                              value: _vm.form.deptCheckStrictly,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deptCheckStrictly", $$v)
                              },
                              expression: "form.deptCheckStrictly",
                            },
                          },
                          [_vm._v("父子联动")]
                        ),
                        _c("el-tree", {
                          ref: "dept",
                          staticClass: "tree-border",
                          attrs: {
                            data: _vm.deptOptions,
                            "show-checkbox": "",
                            "default-expand-all": "",
                            "node-key": "id",
                            "check-strictly": !_vm.form.deptCheckStrictly,
                            "empty-text": "加载中，请稍候",
                            props: _vm.defaultProps,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancelDataScope } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitDataScope },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }